import { axiosClient } from 'api/axios-client';
import { fetchDownloadToken } from 'api/download-token';
import type { ExportQueueEntry } from 'api/export';
import type { RouteParams } from 'app/types';

type Params = Pick<RouteParams, 'organizationId' | 'projectId' | 'patientId'>;

type ExportAction = 'modal' | 'url';

export const exportAccelerometerBin = async (
  { organizationId, projectId, patientId }: Params,
  exportAction: ExportAction
) => {
  if (exportAction === 'modal') {
    const payload = {
      scope_id: organizationId,
      project_id: projectId,
      patient_id: patientId,
      stream_name: 'acc/3ax/4g',
      file_format: 'bin'
    };
    const response: { value: { queue_entry: ExportQueueEntry } } =
      await axiosClient
        .post(`/api/1.0/export/measurement/raw`, payload)
        .then(response => response.data);
    return response.value.queue_entry;
  }
  // exportAction === 'url'
  else {
    const downloadToken = await fetchDownloadToken();
    const params = {
      scope_id: organizationId,
      project_id: projectId,
      patient_id: patientId,
      stream_name: 'acc/3ax/4g',
      file_format: 'bin',
      dt: downloadToken
    };
    const exportUrl = axiosClient.getUri({
      url: '/api/1.0/export/measurement/raw',
      params
    });
    return exportUrl;
  }
};

export const exportAccelerometerCsv = async (
  { organizationId, projectId, patientId }: Params,
  exportAction: ExportAction
) => {
  if (exportAction === 'modal') {
    const payload = {
      scope_id: organizationId,
      project_id: projectId,
      patient_id: patientId,
      stream_name: 'acc/3ax/4g',
      file_format: 'csv'
    };
    const response: { value: { queue_entry: ExportQueueEntry } } =
      await axiosClient
        .post(`/api/1.0/export/measurement/raw`, payload)
        .then(response => response.data);
    return response.value.queue_entry;
  }
  // exportAction === 'url'
  else {
    const downloadToken = await fetchDownloadToken();
    const params = {
      scope_id: organizationId,
      project_id: projectId,
      patient_id: patientId,
      stream_name: 'acc/3ax/4g',
      file_format: 'csv',
      dt: downloadToken
    };
    const exportUrl = axiosClient.getUri({
      url: '/api/1.0/export/measurement/raw',
      params
    });
    return exportUrl;
  }
};
