import React, { useContext } from 'react';
import { ProjectContext } from 'contexts';
import { useTranslate } from 'intl';
import { Header, Modal, Button, List } from 'semantic-ui-react';
import { getFullName } from 'utils/patient';
import ExportAccelerometerBin from './export-accelerometer-bin';
import ExportAccelerometerCsv from './export-accelerometer-csv';
import ExportAveragePdf from './export-average-pdf';
import ExportExtendedPdf from './export-extended-pdf';
import ExportFibionMeasurement from './export-fibion-measurement';
import ExportMeasurement from './export-measurement';
import ExportWeekReport from './export-week-report';
import styles from './styles.module.scss';
import { isExceedDuration } from './utils';

const Item = ({ label, note, children }) => {
  return (
    <List.Item className={styles.item}>
      <div className={styles['item-inner']}>
        <div className={styles.label}>
          {`${label}: `}
          {note && <p className={styles.note}>{note}</p>}
        </div>
        <div className={styles.cta}>{children}</div>
      </div>
    </List.Item>
  );
};

const BulkExportModal = ({ singleExport, patients, open, onClose }) => {
  const t = useTranslate([
    'measurement-export',
    'measurement-bulk-export',
    'common'
  ]);
  const { project } = useContext(ProjectContext);

  const exceed3MonthsDuration = !!patients.find(patient =>
    isExceedDuration({
      startTime: patient.startTime,
      endTime: patient.endTime,
      duration: 91,
      unit: 'days'
    })
  );

  const exceed48HoursDuration = !!patients.find(patient =>
    isExceedDuration({
      startTime: patient.startTime,
      endTime: patient.endTime,
      duration: 48,
      unit: 'hours'
    })
  );

  if (!patients.length) return null;

  return (
    <Modal open={open}>
      <div className={styles.header}>
        <Header className={styles.title}>
          {singleExport
            ? t('measurement-export:header')
            : t('measurement-bulk-export:header')}
        </Header>
        <p className={styles.description}>
          {singleExport
            ? getFullName(patients[0])
            : t('measurement-bulk-export:num-of-patients', {
                count: patients.length
              })}
        </p>
      </div>
      <Modal.Content className={styles.content}>
        <div className={styles.body}>
          <div className={styles.left}>
            <Header size="small">
              {t('measurement-bulk-export:export-type/csv')}
            </Header>
            <List relaxed className={styles.list}>
              <Item label={t('1h-export/label')}>
                <ExportMeasurement
                  interval="1h"
                  patients={patients}
                  onCompleted={onClose}
                >
                  {t('1h-export/action')}
                </ExportMeasurement>
              </Item>

              <Item label={t('15m-export/label')}>
                <ExportMeasurement
                  interval="15m"
                  patients={patients}
                  onCompleted={onClose}
                >
                  {t('15m-export/action')}
                </ExportMeasurement>
              </Item>

              <Item
                label={t('5s-export/label')}
                note={
                  exceed3MonthsDuration ? t('max-duration/3-months') : undefined
                }
              >
                <ExportMeasurement
                  interval="5s"
                  patients={patients}
                  onCompleted={onClose}
                  disabled={exceed3MonthsDuration}
                >
                  {t('5s-export/action')}
                </ExportMeasurement>
              </Item>

              <Item
                label={t('accelerometer-bin-report/label')}
                note={
                  exceed3MonthsDuration ? t('max-duration/3-months') : undefined
                }
              >
                <ExportAccelerometerBin
                  patients={patients}
                  onCompleted={onClose}
                  disabled={exceed3MonthsDuration}
                >
                  {t('accelerometer-bin-report/action')}
                </ExportAccelerometerBin>
              </Item>

              <Item
                label={t('accelerometer-csv-report/label')}
                note={
                  exceed48HoursDuration ? t('max-duration/48-hours') : undefined
                }
              >
                <ExportAccelerometerCsv
                  patients={patients}
                  onCompleted={onClose}
                  disabled={exceed48HoursDuration}
                >
                  {t('accelerometer-csv-report/action')}
                </ExportAccelerometerCsv>
              </Item>

              {project.class === 'project/fibion' && (
                <Item label={t('fibion-export/label')}>
                  <ExportFibionMeasurement
                    patients={patients}
                    onCompleted={onClose}
                  >
                    {t('fibion-export/action')}
                  </ExportFibionMeasurement>
                </Item>
              )}
            </List>
          </div>
          <div className={styles.right}>
            <Header size="small">
              {t('measurement-bulk-export:export-type/pdf')}
            </Header>

            <List relaxed className={styles.list}>
              <Item label={t('pdf-average-report/label')}>
                <ExportAveragePdf patients={patients} onCompleted={onClose}>
                  {t('pdf-average-report/action')}
                </ExportAveragePdf>
              </Item>

              <Item label={t('pdf-week-report/label')}>
                <ExportWeekReport patients={patients} onCompleted={onClose}>
                  {t('pdf-week-report/action')}
                </ExportWeekReport>
              </Item>

              <Item label={t('pdf-extended-report/label')}>
                <ExportExtendedPdf patients={patients} onCompleted={onClose}>
                  {t('pdf-extended-report/action')}
                </ExportExtendedPdf>
              </Item>
            </List>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={onClose}>
          {t('common:close')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default BulkExportModal;
