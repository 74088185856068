export const numericSort = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const naturalSort = (a, b) => {
  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: 'base'
  });
};

export const characterSort = (a, b) => {
  for (let i = 0; i < Math.min(a.length, b.length); i++) {
    if (a[i] !== b[i]) {
      return a[i].localeCompare(b[i], undefined, {
        numeric: false,
        sensitivity: 'base'
      });
    }
  }
  return a.length - b.length;
};

export const createSorter = (...params) => {
  const sortParams = [...params, 'idx'];
  const sorter = (a, b) => {
    const len = sortParams.length;
    let i = 0;
    let sortOrder = 0;

    while (sortOrder === 0 && i < len) {
      const customGetter =
        typeof sortParams[i] === 'function' ? sortParams[i] : null;
      const valueA = customGetter ? customGetter(a) : a[sortParams[i]];
      const valueB = customGetter ? customGetter(b) : b[sortParams[i]];

      sortOrder =
        typeof valueA === 'string' && typeof valueB === 'string'
          ? naturalSort(valueA, valueB)
          : numericSort(valueA, valueB);

      i++;
    }
    return sortOrder;
  };
  return sorter;
};

export const createSorterByCharacter = (...params) => {
  const sortParams = [...params, 'idx'];
  const sorter = (a, b) => {
    const len = sortParams.length;
    let i = 0;
    let sortOrder = 0;

    while (sortOrder === 0 && i < len) {
      const customGetter =
        typeof sortParams[i] === 'function' ? sortParams[i] : null;
      const valueA = customGetter ? customGetter(a) : a[sortParams[i]];
      const valueB = customGetter ? customGetter(b) : b[sortParams[i]];

      sortOrder =
        typeof valueA === 'string' && typeof valueB === 'string'
          ? characterSort(valueA, valueB)
          : numericSort(valueA, valueB);

      i++;
    }
    return sortOrder;
  };
  return sorter;
};
