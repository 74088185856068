import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'hooks';
import { useTranslate } from 'intl';
import { Button, Form, Header, Input, Segment } from 'semantic-ui-react';
import { changePassword } from 'api/user';
import ErrorMessage from 'components/error-message';

const ChangePassword = ({ onSuccess, onCancel }) => {
  const t = useTranslate('settings');

  const { form, onChanges, onSubmit, submitting, error, onClearError } =
    useForm({
      initialForm: {
        currentPassword: '',
        newPassword: '',
        newPassword2: ''
      },
      apiSubmit: form => changePassword(form).then(onSuccess)
    });

  return (
    <Segment>
      <Header>{t('change-password')}</Header>
      <Form onSubmit={onSubmit} error={!!error}>
        <Form.Field required>
          <label>{t('current-password')}</label>
          <Input
            type="password"
            required
            placeholder={t('current-password')}
            value={form.currentPassword}
            onChange={(event, { value }) => onChanges.currentPassword(value)}
          />
        </Form.Field>
        <Form.Field required>
          <label>{t('new-password')}</label>
          <Input
            type="password"
            required
            placeholder={t('new-password')}
            value={form.newPassword}
            onChange={(event, { value }) => onChanges.newPassword(value)}
          />
        </Form.Field>
        <Form.Field required>
          <label>{t('new-password2')}</label>
          <Input
            type="password"
            required
            placeholder={t('new-password2')}
            value={form.newPassword2}
            onChange={(event, { value }) => onChanges.newPassword2(value)}
          />
        </Form.Field>

        {error && (
          <ErrorMessage
            error={
              error.statusCode === 35
                ? { type: 'intl', message: 'password-error' } // Issue #1443
                : error
            }
            onDismiss={onClearError}
          />
        )}

        <div>
          <Button type="button" disabled={submitting} onClick={onCancel}>
            {t('common:cancel')}
          </Button>
          <Button type="submit" color="blue" loading={submitting}>
            {t('change')}
          </Button>
        </div>
      </Form>
    </Segment>
  );
};

ChangePassword.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ChangePassword;
