import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import TimeInput from 'components/time-input';
import { momentTimezone } from 'utils/date';

const PlotHourSelect = ({ timezone, value, onChange }) => {
  const handleChange = useCallback(
    (event, { value }) => {
      onChange(value);
    },
    [onChange]
  );

  const onAdd = useCallback(
    quantity => {
      const nextDate = momentTimezone(value, timezone)
        .add(quantity, 'hour')
        .format();
      onChange(nextDate);
    },
    [value, onChange]
  );

  const onClickPrev = useCallback(() => onAdd(-1), [onAdd]);
  const onClickNext = useCallback(() => onAdd(1), [onAdd]);

  const hour = momentTimezone(value, timezone).format('HH:mm');
  const disabledPrev = hour === '00:00';
  const disabledNext = hour === '23:00';

  return (
    <div style={{ display: 'flex' }}>
      <Button
        basic
        icon="left arrow"
        disabled={disabledPrev}
        onClick={onClickPrev}
      />
      <Button basic style={{ padding: '0' }}>
        <TimeInput timezone={timezone} value={value} onChange={handleChange} />
      </Button>
      <Button
        basic
        icon="right arrow"
        disabled={disabledNext}
        onClick={onClickNext}
      />
    </div>
  );
};

PlotHourSelect.propTypes = {
  timezone: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PlotHourSelect;
