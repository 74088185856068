import React from 'react';
import moment from 'moment';
import Dash from 'components/dash';
import Duration from 'components/duration';
import { momentTimezone } from 'utils/date';
import styles from './styles.module.css';

export const StartTime = ({ data }) => {
  if (!data.startTime) return <Dash />;

  return (
    <span>
      {momentTimezone(data.startTime, data.timezone).format('L')}
      <br />
      <span className={styles.sub}>
        {momentTimezone(data.startTime, data.timezone).format('HH:mm')}
      </span>
    </span>
  );
};

export const StartTimeDetails = ({ data }) => {
  if (!data.startTime) return <Dash />;

  return (
    <span>
      {momentTimezone(data.startTime, data.timezone).format('L')}
      {`-`}
      {momentTimezone(data.startTime, data.timezone).format('HH:mm')}
    </span>
  );
};

export const EndTime = ({ data }) => {
  if (!data.startTime) return <Dash />;

  if (data.endTime) {
    return (
      <span>
        {momentTimezone(data.endTime, data.timezone).format('L')}
        <br />
        <span className={styles.sub}>
          {momentTimezone(data.endTime, data.timezone).format('HH:mm')}
          {data.startTime !== data.endTime && (
            <span>
              {` - `}
              <Duration from={data.startTime} to={data.endTime} level={2} />
            </span>
          )}
        </span>
      </span>
    );
  }

  return (
    <span>
      <span>&nbsp;</span>
      <br />
      <span className={styles.sub}>
        <Duration from={data.startTime} to={moment().toISOString()} level={2} />
      </span>
    </span>
  );
};

export const EndTimeDetails = ({ data }) => {
  if (!data.endTime) return <Dash />;

  return (
    <span>
      {momentTimezone(data.endTime, data.timezone).format('L')}
      {`-`}
      {momentTimezone(data.endTime, data.timezone).format('HH:mm')}
    </span>
  );
};

export const DurationDetails = ({ data }) => {
  if (!data.startTime) return <Dash />;

  if (data.endTime) {
    return <Duration from={data.startTime} to={data.endTime} />;
  }

  return <Duration from={data.startTime} to={moment().toISOString()} />;
};
