import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslate } from 'intl';
import WebAppVersion from 'fragments/web-app-version';
import { Header } from 'semantic-ui-react';
import { getSessionKey, setSessionKey } from 'api/auth-utils';
import { requestTwoFaCode } from 'api/authenticate';
import ConfirmationCodeForm from './confirmation-code-form';
import styles from './styles.module.scss';

const VerifyTwoFaCode = ({ payload, onBack }) => {
  const t = useTranslate('login');

  useEffect(() => {
    requestTwoFaCode({
      ...payload,
      sessionKey: getSessionKey(payload.email)
    }).then(response => {
      const { sessionKey } = response;
      setSessionKey(payload.email, sessionKey);
    });
  }, []);

  return (
    <div className={styles.main}>
      <Header as="h2" color="blue" textAlign="center">
        {t('enter-two-fa-code/title')}
        <Header.Subheader style={{ marginTop: '1rem' }}>
          {t('enter-two-fa-code/description')}
        </Header.Subheader>
      </Header>

      <div className={styles.codes}>
        <ConfirmationCodeForm payload={payload} />
        <div style={{ textAlign: 'center', marginTop: '0.5rem' }}>
          <Link onClick={onBack}>{t('return-to-login')}</Link>
        </div>
        <WebAppVersion />
      </div>
    </div>
  );
};

export default VerifyTwoFaCode;
