import { axiosClient } from 'api/axios-client';
import { fetchDownloadToken } from 'api/download-token';
import type { ExportQueueEntry } from 'api/export';
import type { RouteParams } from 'app/types';

type ExportAction = 'modal' | 'url';

export const exportActivityData = async (
  {
    organizationId,
    projectId,
    patientId
  }: Pick<RouteParams, 'organizationId' | 'projectId' | 'patientId'>,
  exportAction: ExportAction
) => {
  if (exportAction === 'modal') {
    const payload = {
      scope_id: organizationId,
      project_id: projectId,
      patient_id: patientId,
      data_format: 'detailed2',
      file_format: 'csv.gz'
    };

    const response: { value: { queue_entry: ExportQueueEntry } } =
      await axiosClient
        .post(`/api/1.0/export/measurement`, payload)
        .then(response => response.data);
    return response.value.queue_entry;
  }
  // exportAction === 'url'
  else {
    const downloadToken = await fetchDownloadToken();

    const params = {
      scope_id: organizationId,
      project_id: projectId,
      patient_id: patientId,
      data_format: 'detailed2',
      file_format: 'csv.gz',
      dt: downloadToken
    };

    const exportUrl = axiosClient.getUri({
      url: '/export/measurement',
      params
    });

    return exportUrl;
  }
};

export const exportDiary = async (
  {
    organizationId,
    projectId,
    patientId
  }: Pick<RouteParams, 'organizationId' | 'projectId' | 'patientId'>,
  exportAction: ExportAction
) => {
  if (exportAction === 'modal') {
    const payload = {
      scope_id: organizationId,
      project_id: projectId,
      patient_id: patientId,
      file_format: 'csv'
    };

    const response: { value: { queue_entry: ExportQueueEntry } } =
      await axiosClient
        .post(`/api/1.0/export/diary`, payload)
        .then(response => response.data);
    return response.value.queue_entry;
  }
  // exportAction === 'url'
  else {
    const downloadToken = await fetchDownloadToken();

    const params = {
      scope_id: organizationId,
      project_id: projectId,
      patient_id: patientId,
      dt: downloadToken
    };

    const exportUrl = axiosClient.getUri({
      url: '/export/diary',
      params
    });

    return exportUrl;
  }
};

export const exportCSV = async (
  {
    organizationId,
    projectId,
    patientId
  }: Pick<RouteParams, 'organizationId' | 'projectId' | 'patientId'>,
  exportAction: ExportAction
) => {
  if (exportAction === 'modal') {
    const payload = {
      scope_id: organizationId,
      project_id: projectId,
      patient_id: patientId,
      data_format: 'summary1h',
      file_format: 'csv'
    };
    const response: { value: { queue_entry: ExportQueueEntry } } =
      await axiosClient
        .post(`/api/1.0/export/measurement`, payload)
        .then(response => response.data);
    return response.value.queue_entry;
  }
  // exportAction === 'url'
  else {
    const downloadToken = await fetchDownloadToken();
    const params = {
      scope_id: organizationId,
      project_id: projectId,
      patient_id: patientId,
      data_format: 'summary1h',
      dt: downloadToken
    };

    const exportUrl = axiosClient.getUri({
      url: '/export/measurement',
      params
    });
    return exportUrl;
  }
};

export const exportCSVDetailed = async (
  {
    organizationId,
    projectId,
    patientId
  }: Pick<RouteParams, 'organizationId' | 'projectId' | 'patientId'>,
  exportAction: ExportAction
) => {
  if (exportAction === 'modal') {
    const payload = {
      scope_id: organizationId,
      project_id: projectId,
      patient_id: patientId,
      data_format: 'detailed_motus',
      file_format: 'csv.gz'
    };

    const response: { value: { queue_entry: ExportQueueEntry } } =
      await axiosClient
        .post(`/api/1.0/export/measurement`, payload)
        .then(response => response.data);
    return response.value.queue_entry;
  }
  // exportAction === 'url'
  else {
    const downloadToken = await fetchDownloadToken();

    const params = {
      scope_id: organizationId,
      project_id: projectId,
      patient_id: patientId,
      data_format: 'detailed_motus',
      file_format: 'csv.gz',
      dt: downloadToken
    };

    const exportUrl = axiosClient.getUri({
      url: '/export/measurement',
      params
    });

    return exportUrl;
  }
};

export const exportCSVSummary = async (
  {
    organizationId,
    projectId,
    patientId
  }: Pick<RouteParams, 'organizationId' | 'projectId' | 'patientId'>,
  exportAction: ExportAction
) => {
  if (exportAction === 'modal') {
    const payload = {
      scope_id: organizationId,
      project_id: projectId,
      patient_id: patientId,
      data_format: 'summary15m_motus',
      file_format: 'csv'
    };

    const response: { value: { queue_entry: ExportQueueEntry } } =
      await axiosClient
        .post(`/api/1.0/export/measurement`, payload)
        .then(response => response.data);
    return response.value.queue_entry;
  }
  // exportAction === 'url'
  else {
    const downloadToken = await fetchDownloadToken();

    const params = {
      scope_id: organizationId,
      project_id: projectId,
      patient_id: patientId,
      data_format: 'summary15m_motus',
      file_format: 'csv',
      dt: downloadToken
    };

    const exportUrl = axiosClient.getUri({
      url: '/export/measurement',
      params
    });

    return exportUrl;
  }
};
