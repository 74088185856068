import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fetchPlotDay, fetchPlotHour } from 'api/patients';
import { Fetcher, HourSelect, Layout, PlotName } from 'components/plot';

const MeasurmentPlotDerived = ({ date, timezone, stream }) => {
  const [hour, setHour] = useState('');

  return (
    <>
      <PlotName stream={stream} />

      <Fetcher
        type="day"
        apiFetch={fetchPlotDay}
        date={date}
        timezone={timezone}
        stream={stream}
        onSelectTick={setHour}
      />

      {hour && (
        <Layout
          type="hour"
          timeSelect={
            <HourSelect timezone={timezone} value={hour} onChange={setHour} />
          }
        >
          <Fetcher
            type="hour"
            apiFetch={fetchPlotHour}
            date={hour}
            timezone={timezone}
            stream={stream}
          />
        </Layout>
      )}
    </>
  );
};

MeasurmentPlotDerived.propTypes = {
  date: PropTypes.string.isRequired,
  timezone: PropTypes.string,
  stream: PropTypes.string.isRequired
};

export default MeasurmentPlotDerived;
