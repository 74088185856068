import moment from 'moment';

export const isDateChange = (d1, d2) => {
  if (!d1 && !d2) return false;
  if (!d1 || !d2) return true;
  const t1 = new Date(d1).valueOf();
  const t2 = new Date(d2).valueOf();
  return t1 !== t2;
};

export const getDiffTimezoneOffset = timezone => {
  const localTimeOffset = moment().utcOffset();
  const timezoneOffset = moment().tz(timezone).utcOffset();
  const diffTimezoneOffset = timezoneOffset - localTimeOffset;
  return diffTimezoneOffset;
};

export const fakeLocalAtTimezone = (time, timezone) => {
  const diffTimezoneOffset = getDiffTimezoneOffset(timezone);
  return moment(time)
    .add(diffTimezoneOffset, 'minutes')
    .format('YYYY-MM-DD HH:mm');
};

export const revertFakeLocalAtTimezone = (time, timezone) => {
  const diffTimezoneOffset = getDiffTimezoneOffset(timezone);
  return moment(time).subtract(diffTimezoneOffset, 'minutes').toISOString();
};

export const momentTimezone = (time, timezone) => {
  return timezone ? moment(time).tz(timezone) : moment(time);
};
