import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Icon, Message } from 'semantic-ui-react';
import styles from './styles.module.css';

export const RawErrorMessage = ({ error }) => {
  const t = useTranslate('error');
  return (
    <span>{error.type === 'intl' ? t(error.message) : error.message}</span>
  );
};

const ErrorMessage = ({ error, onDismiss, ...otherProps }) => {
  const t = useTranslate('error');

  if (!error) {
    return null;
  }

  return (
    <Message error onDismiss={onDismiss} {...otherProps}>
      <div className={styles.main}>
        <Icon className={styles.icon} name="warning circle" />
        <div
          className={styles.message}
          dangerouslySetInnerHTML={{
            __html: error.type === 'intl' ? t(error.message) : error.message
          }}
        />
      </div>
    </Message>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.object,
  onDismiss: PropTypes.func
};

export default ErrorMessage;
