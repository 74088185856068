import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import routes from 'routes';
import GatewayDetails from 'pages/admin-gateway-details';
import Gateways from 'pages/admin-gateways';
import NearbySensorDetails from 'pages/admin-nearby-sensor-details';
import NearbySensors from 'pages/admin-nearby-sensors';
import Reports from 'pages/admin-reports';
import BLE from 'pages/ble';
import BrowseData from 'pages/browse-data';
import CrashMe from 'pages/crash-me';
import ExternalPatients from 'pages/external-patients';
import ForgotPassword from 'pages/forgot-password';
import HospitalPatients from 'pages/hospital-patients';
import ICompAdmin from 'pages/icomp-admin';
import ICompAdminDepartmentProfile from 'pages/icomp-admin-department-profile';
import ICompAdminDepartmentSensorOverview from 'pages/icomp-admin-department-sensor-overview';
import ICompAdminDepartmentUserOverview from 'pages/icomp-admin-department-user-overview';
import ICompAdminDepartmentUserProfile from 'pages/icomp-admin-department-user-profile';
import ICompAdminDepartmentUserRegistration from 'pages/icomp-admin-department-user-registration';
import ICompAdminSensorOverview from 'pages/icomp-admin-sensor-overview';
import ICompAdminUserOverview from 'pages/icomp-admin-user-overview';
import ICompAdminUserProfile from 'pages/icomp-admin-user-profile';
import ICompAdminUserRegistration from 'pages/icomp-admin-user-registration';
import ICompDepartments from 'pages/icomp-departments';
import ICompPatientAdd from 'pages/icomp-patient-add';
import ICompPatientPlots from 'pages/icomp-patient-plots';
import ICompPatients from 'pages/icomp-patients';
import ICompSensorDetails from 'pages/icomp-sensor-details';
import ICompSensors from 'pages/icomp-sensors';
import ISpinePatientDetails from 'pages/ispine-patient-details';
import ISpinePatients from 'pages/ispine-patients';
import Login from 'pages/login';
import Logout from 'pages/logout';
import NFASensorDetails from 'pages/nfa-sensor-details';
import NFASensors from 'pages/nfa-sensors';
import NFASubjects from 'pages/nfa-subjects';
import Organizations from 'pages/organizations';
import PatientDetails from 'pages/patient-details';
import Patients from 'pages/patients';
import Projects from 'pages/projects';
import ResetPage from 'pages/reset-page';
import ResetPassword from 'pages/reset-password';
import SensorDetails from 'pages/sensor-details';
import Sensors from 'pages/sensors';
import Settings from 'pages/settings';
import SimpleSensorDetails from 'pages/simple-sensor-details';
import SimpleSensors from 'pages/simple-sensors';
import VerifyConfirmationCode from 'pages/verify-confirmation-code';
import { getVendor } from 'utils/vendor';
import RequireAuth from './require-auth';
import RouteSubscriber from './route-subscriber';

const getHomeRoute = () => {
  const vendor = getVendor();
  switch (vendor) {
    case 'icomp':
      return routes.iCompDepartments();
    case 'nfa':
      return routes.nfaOrganizations();
    default:
      return routes.organizations();
  }
};

const AppRouter = () => (
  <BrowserRouter>
    <Routes>
      {/* Common Routes */}
      <Route path={routes.login()} element={<Login />} />
      <Route path={routes.logout()} element={<Logout />} />
      <Route path={routes.reset()} element={<ResetPage />} />
      <Route path={routes.forgotPassword()} element={<ForgotPassword />} />
      <Route
        path={routes.verifyConfirmationCode()}
        element={<VerifyConfirmationCode />}
      />
      <Route path={routes.resetPassword()} element={<ResetPassword />} />
      <Route
        path={routes.settings()}
        element={
          <RequireAuth>
            <Settings />
          </RequireAuth>
        }
      />
      <Route path="/r/crashme" element={<CrashMe />} />
      <Route path="*" element={<Navigate to={getHomeRoute()} replace />} />

      {/* SENS Routes */}
      <Route
        path={routes.organizations()}
        element={
          <RequireAuth>
            <Organizations />
          </RequireAuth>
        }
      />
      <Route
        path={routes.projects(':organizationId')}
        element={
          <RequireAuth>
            <RouteSubscriber />
            <Projects />
          </RequireAuth>
        }
      />
      <Route
        path={routes.sensors(':organizationId', ':projectId')}
        element={
          <RequireAuth>
            <RouteSubscriber />
            <Sensors />
          </RequireAuth>
        }
      />
      <Route
        path={routes.sensorDetails(
          ':organizationId',
          ':projectId',
          ':sensorId'
        )}
        element={
          <RequireAuth>
            <RouteSubscriber />
            <SensorDetails />
          </RequireAuth>
        }
      />
      <Route
        path={routes.simpleSensors(':organizationId', ':projectId')}
        element={
          <RequireAuth>
            <RouteSubscriber />
            <SimpleSensors />
          </RequireAuth>
        }
      />
      <Route
        path={routes.simpleSensorDetails(
          ':organizationId',
          ':projectId',
          ':sensorId'
        )}
        element={
          <RequireAuth>
            <RouteSubscriber />
            <SimpleSensorDetails />
          </RequireAuth>
        }
      />
      <Route
        path={routes.patients(':organizationId', ':projectId')}
        element={
          <RequireAuth>
            <RouteSubscriber />
            <Patients />
          </RequireAuth>
        }
      />
      <Route
        path={routes.patientDetails(
          ':organizationId',
          ':projectId',
          ':patientId'
        )}
        element={
          <RequireAuth>
            <RouteSubscriber />
            <PatientDetails />
          </RequireAuth>
        }
      />
      {/* Temporary hide Browse Data page */}
      <Route
        path={routes.browseData(':organizationId', ':projectId')}
        element={
          <RequireAuth>
            <RouteSubscriber />
            <BrowseData />
          </RequireAuth>
        }
      />
      <Route
        path={routes.iSpinePatients(':organizationId', ':projectId')}
        element={
          <RequireAuth>
            <RouteSubscriber />
            <ISpinePatients />
          </RequireAuth>
        }
      />
      <Route
        path={routes.iSpinePatientDetails(
          ':organizationId',
          ':projectId',
          ':measurementId'
        )}
        element={
          <RequireAuth>
            <RouteSubscriber />
            <ISpinePatientDetails />
          </RequireAuth>
        }
      />
      <Route
        path={routes.hospitalPatients(':organizationId', ':projectId')}
        element={
          <RequireAuth>
            <RouteSubscriber />
            <HospitalPatients />
          </RequireAuth>
        }
      />
      <Route
        path={routes.externalPatients(':organizationId', ':projectId')}
        element={
          <RequireAuth>
            <RouteSubscriber />
            <ExternalPatients />
          </RequireAuth>
        }
      />
      <Route
        path={routes.ble(':organizationId', ':projectId')}
        element={
          <RequireAuth>
            <BLE />
          </RequireAuth>
        }
      />
      <Route
        path={routes.gateways()}
        element={
          <RequireAuth>
            <Gateways />
          </RequireAuth>
        }
      />
      <Route
        path={routes.gatewayDetails(':gatewayId')}
        element={
          <RequireAuth>
            <GatewayDetails />
          </RequireAuth>
        }
      />
      <Route
        path={routes.gatewayReports(':gatewayId')}
        element={
          <RequireAuth>
            <Reports />
          </RequireAuth>
        }
      />
      <Route
        path={routes.gatewayNearbySensors(':gatewayId')}
        element={
          <RequireAuth>
            <NearbySensors />
          </RequireAuth>
        }
      />
      <Route
        path={routes.gatewayNearbySensorDetails(':gatewayId', ':sensorId')}
        element={
          <RequireAuth>
            <NearbySensorDetails />
          </RequireAuth>
        }
      />

      {/* ICOMP Routes */}
      <Route
        path={routes.iCompDepartments()}
        element={
          <RequireAuth>
            <ICompDepartments />
          </RequireAuth>
        }
      />
      <Route
        path={routes.iCompSensors(':hospitalId', ':departmentId', ':projectId')}
        element={
          <RequireAuth>
            <ICompSensors />
          </RequireAuth>
        }
      />
      <Route
        path={routes.iCompSensorDetails(
          ':hospitalId',
          ':departmentId',
          ':projectId',
          ':sensorId'
        )}
        element={
          <RequireAuth>
            <ICompSensorDetails />
          </RequireAuth>
        }
      />
      <Route
        path={routes.iCompPatients(
          ':hospitalId',
          ':departmentId',
          ':projectId'
        )}
        element={
          <RequireAuth>
            <ICompPatients />
          </RequireAuth>
        }
      />
      <Route
        path={routes.iCompPatientAdd(
          ':hospitalId',
          ':departmentId',
          ':projectId'
        )}
        element={
          <RequireAuth>
            <ICompPatientAdd />
          </RequireAuth>
        }
      />
      <Route
        path={routes.iCompPatientPlots(
          ':hospitalId',
          ':departmentId',
          ':projectId',
          ':patientId'
        )}
        element={
          <RequireAuth>
            <ICompPatientPlots />
          </RequireAuth>
        }
      />
      <Route
        path={routes.iCompAdmin()}
        element={
          <RequireAuth>
            <ICompAdmin />
          </RequireAuth>
        }
      />
      <Route
        path={routes.iCompAdminUserOverview(':hospitalId')}
        element={
          <RequireAuth>
            <ICompAdminUserOverview />
          </RequireAuth>
        }
      />
      <Route
        path={routes.iCompAdminUserProfile(':hospitalId', ':userId')}
        element={
          <RequireAuth>
            <ICompAdminUserProfile />
          </RequireAuth>
        }
      />
      <Route
        path={routes.iCompAdminUserRegistration(':hospitalId')}
        element={
          <RequireAuth>
            <ICompAdminUserRegistration />
          </RequireAuth>
        }
      />
      <Route
        path={routes.iCompAdminSensorOverview(':hospitalId')}
        element={
          <RequireAuth>
            <ICompAdminSensorOverview />
          </RequireAuth>
        }
      />
      <Route
        path={routes.iCompAdminDepartmentProfile(
          ':hospitalId',
          ':departmentId'
        )}
        element={
          <RequireAuth>
            <ICompAdminDepartmentProfile />
          </RequireAuth>
        }
      />
      <Route
        path={routes.iCompAdminDepartmentUserOverview(
          ':hospitalId',
          ':departmentId'
        )}
        element={
          <RequireAuth>
            <ICompAdminDepartmentUserOverview />
          </RequireAuth>
        }
      />
      <Route
        path={routes.iCompAdminDepartmentUserProfile(
          ':hospitalId',
          ':departmentId',
          ':userId'
        )}
        element={
          <RequireAuth>
            <ICompAdminDepartmentUserProfile />
          </RequireAuth>
        }
      />
      <Route
        path={routes.iCompAdminDepartmentUserRegistration(
          ':hospitalId',
          ':departmentId'
        )}
        element={
          <RequireAuth>
            <ICompAdminDepartmentUserRegistration />
          </RequireAuth>
        }
      />
      <Route
        path={routes.iCompAdminDepartmentSensorOverview(
          ':hospitalId',
          ':departmentId'
        )}
        element={
          <RequireAuth>
            <ICompAdminDepartmentSensorOverview />
          </RequireAuth>
        }
      />

      {/* NFA Routes */}
      <Route
        path={routes.nfaOrganizations()}
        element={
          <RequireAuth>
            <Organizations />
          </RequireAuth>
        }
      />
      <Route
        path={routes.nfaProjects(':organizationId')}
        element={
          <RequireAuth>
            <Projects />
          </RequireAuth>
        }
      />
      <Route
        path={routes.nfaSubjects(':organizationId', ':projectId')}
        element={
          <RequireAuth>
            <NFASubjects />
          </RequireAuth>
        }
      />
      <Route
        path={routes.nfaSensors(':organizationId', ':projectId')}
        element={
          <RequireAuth>
            <NFASensors />
          </RequireAuth>
        }
      />
      <Route
        path={routes.nfaSensorDetails(
          ':organizationId',
          ':projectId',
          ':sensorId'
        )}
        element={
          <RequireAuth>
            <NFASensorDetails />
          </RequireAuth>
        }
      />
    </Routes>
  </BrowserRouter>
);

export default AppRouter;
