const stackOrders = [
  'activity/other/time',
  'activity/exercise/time',
  'activity/cycling/time',
  'activity/walking/time',
  'activity/upright_bike/time',
  'activity/upright_run/time',
  'activity/upright_moderate/time',
  'activity/upright_fastwalk/time',
  'activity/restless_sitting/time',
  'activity/calm_sitting/time',
  'activity/upright_walk/time',
  'activity/upright_sporadic_walk/time',
  'activity/upright_stand/time',
  'activity/sitting_movement/time',
  'activity/sitting/time',
  'activity/restless_lying/time',
  'activity/calm_lying/time',
  'activity/lying+sitting/time',
  'activity/lying/time',
  'activity/lying_sitting_movement/time',
  'activity/lying_movement/time',
  'activity/lying_sitting_rest/time',
  'activity/lying_sitting/time',
  'activity/lying_rest/time',
  'activity/sleep_active/time',
  'activity/sleep_movement/time',
  'activity/sleep_no_movement/time'
];

const legendOrders = [
  'activity/exercise/time',
  'activity/cycling/time',
  'activity/upright_bike/time',
  'activity/upright_run/time',
  'activity/upright_moderate/time',
  'activity/upright_fastwalk/time',
  'activity/upright_walk/time',
  'activity/upright_sporadic_walk/time',
  'activity/upright_stand/time',
  'activity/sitting_movement/time',
  'activity/sitting/time',
  'activity/lying/time',
  'activity/lying+sitting/time',
  'activity/lying_sitting_movement/time',
  'activity/lying_sitting_rest/time',
  'activity/lying_sitting/time',
  // --- DEMOS
  'activity/calm_lying/time',
  'activity/calm_sitting/time',
  'activity/walking/time',
  'activity/restless_lying/time',
  'activity/restless_sitting/time',
  'activity/other/time'
];

const lastOrder = 'general/nodata/time';

const sort = (categories, firstOrders, lastOrder) => {
  const arr1 = categories
    .filter(c => firstOrders.includes(c))
    .sort((a, b) => firstOrders.indexOf(a) - firstOrders.indexOf(b));
  const arr2 = categories.filter(
    c => c !== lastOrder && !firstOrders.includes(c)
  );
  const arr3 = categories.filter(c => c === lastOrder);
  return [...arr1, ...arr2, ...arr3];
};

export const getStacks = categories => sort(categories, stackOrders, lastOrder);

export const getLegends = categories =>
  sort(categories, legendOrders, lastOrder);

export default { getStacks, getLegends };
