import moment from 'moment';
import { axiosClient } from 'api/axios-client';
import { fetchDownloadToken } from 'api/download-token';
import type { ExportQueueEntry } from 'api/export';
import type {
  SimpleSensorExportAction,
  SimpleSensorExportPayload
} from '../types';

export const exportDailyRythmReport = async (
  exportPayload: SimpleSensorExportPayload,
  exportAction?: SimpleSensorExportAction
) => {
  const { organizationId, projectId, sensorId, lang, startDate, endDate } =
    exportPayload;

  if (exportAction === 'modal') {
    const payload = {
      scope_id: organizationId,
      project_id: projectId,
      sensor_id: sensorId,
      lang,
      start_date: moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
      end_date: moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
      alg_profile: 'person/demos1',
      reverse: true,
      tz: 'Europe/Copenhagen'
    };
    const response: { value: { queue_entry: ExportQueueEntry } } =
      await axiosClient
        .post('/api/1.0/export/sensor/report', payload)
        .then(response => response.data);
    return response.value.queue_entry;
  }
  // exportAction === 'url'
  else {
    const downloadToken = await fetchDownloadToken();
    const params = {
      org_id: organizationId,
      project_id: projectId,
      sensor_id: sensorId,
      lang,
      start_date: moment(startDate).format('YYYY-MM-DDTHH:mm:ss'),
      end_date: moment(endDate).format('YYYY-MM-DDTHH:mm:ss'),
      alg_profile: 'person/demos1',
      reverse: true,
      tz: 'Europe/Copenhagen',
      dt: downloadToken
    };
    const exportUrl = axiosClient.getUri({
      url: '/export/sensor/report',
      params
    });
    return exportUrl;
  }
};
