import { useEffect, useState, useRef } from 'react';
import { useFetch, useToggleOpen } from 'hooks';
import { fetchExportQueueStatus } from 'api/export';
import { ExportControllerContext } from './context';
import { ExportControllerModal } from './modal';

export * from './trigger';
export * from './context';

export const ExportControllerContextProvider = ({ children }) => {
  const intervalRef = useRef(null);
  const [isOpenExportModal, onOpenExportModal, onCloseExportModal] =
    useToggleOpen(false);

  const {
    data: exportEntries = [],
    error: exportEntriesError,
    fetching: isFetchingExportEntries,
    refetch: refetchExportEntries,
    onUpdate: setExportEntries
  } = useFetch({
    apiFetch: fetchExportQueueStatus,
    refresh: 20000
  });

  const [downloadedUuids, setDownloadedUuids] = useState([]);

  useEffect(() => {
    if (isOpenExportModal) {
      intervalRef.current = window.setInterval(() => {
        refetchExportEntries();
      }, 5000);
    }
    return () => {
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [isOpenExportModal]);

  const addExportEntry = entry => {
    const foundIndex = exportEntries.findIndex(
      item => item.uuid === entry.uuid
    );
    if (foundIndex !== -1) {
      const newExportEntries = exportEntries.slice();
      newExportEntries[foundIndex] = entry;
      setExportEntries(newExportEntries);
    } else {
      const newExportEntries = [...exportEntries, entry];
      setExportEntries(newExportEntries);
    }
  };

  const removeExportEntry = entry => {
    const newExportEntries = exportEntries.filter(
      item => item.uuid !== entry.uuid
    );
    setExportEntries(newExportEntries);
  };

  const updateExportEntry = entry => {
    const foundIndex = exportEntries.findIndex(
      item => item.uuid === entry.uuid
    );
    if (foundIndex !== -1) {
      const newExportEntries = exportEntries.slice();
      newExportEntries[foundIndex] = entry;
      setExportEntries(newExportEntries);
    }
  };

  return (
    <ExportControllerContext.Provider
      value={{
        isOpenExportModal,
        onOpenExportModal,
        onCloseExportModal,
        isFetchingExportEntries,
        exportEntries,
        exportEntriesError,
        refetchExportEntries,
        setExportEntries,
        addExportEntry,
        removeExportEntry,
        updateExportEntry,
        downloadedUuids,
        setDownloadedUuids
      }}
    >
      {children}
      {isOpenExportModal && (
        <ExportControllerModal open onClose={onCloseExportModal} />
      )}
    </ExportControllerContext.Provider>
  );
};
