import React, { useMemo, useState } from 'react';
import { usePrevious } from 'hooks';
import { Divider } from 'semantic-ui-react';
import { fetchStreamOptions } from 'api/measurements';
import {
  DateSelect,
  Layout,
  StreamSelect,
  getStreamType,
  withStreamOptions
} from 'components/plot';
import { momentTimezone } from 'utils/date';
import PlotDerived from './plot-derived';
import PlotRaw from './plot-raw';

const getDefaultDate = (endTime, timezone) => {
  const today = momentTimezone(undefined, timezone).startOf('day');
  if (!endTime) {
    return today.format();
  }
  const endDate = momentTimezone(endTime, timezone).startOf('day');
  const date = endDate.isAfter(today) ? today : endDate;
  return date.format();
};

const PatientPlot = ({ patient, streamOptions }) => {
  const { startTime, endTime, timezone, sensors } = patient;
  const [date, setDate] = useState(() => getDefaultDate(endTime, timezone));
  const [stream, setStream] = useState(streamOptions[0]);

  const minDate = useMemo(
    () => momentTimezone(startTime, timezone).startOf('day').format(),
    [startTime, timezone]
  );
  const maxDate = useMemo(
    () => momentTimezone(endTime, timezone).startOf('day').format(),
    [endTime, timezone]
  );

  const prevPatient = usePrevious(patient);
  if (endTime !== prevPatient.endTime || timezone !== prevPatient.timezone) {
    const newDefaultDate = getDefaultDate(endTime, timezone);
    if (date !== getDefaultDate(endTime, timezone)) {
      setDate(newDefaultDate);
    }
  }

  const streamType = getStreamType(stream);

  return (
    <Layout
      timeSelect={
        <DateSelect
          timezone={timezone}
          value={date}
          onChange={setDate}
          minDate={minDate}
          maxDate={maxDate}
        />
      }
      streamSelect={
        <StreamSelect
          value={stream}
          onChange={setStream}
          options={streamOptions}
        />
      }
    >
      {streamType === 'derived' ? (
        <PlotDerived date={date} timezone={timezone} stream={stream} />
      ) : (
        sensors.map((sensor, index) => (
          <>
            <PlotRaw
              key={index}
              date={date}
              timezone={timezone}
              stream={stream}
              sensor={sensor}
              streamOptions={streamOptions.filter(
                item => getStreamType(item) === 'raw'
              )}
            />
            {index < sensors.length - 1 && <Divider section />}
          </>
        ))
      )}
    </Layout>
  );
};

export default withStreamOptions(PatientPlot, fetchStreamOptions);
