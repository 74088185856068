import { fetchAlgorithms } from 'api/algorithms';
import type { RouteParams } from 'app/types';
import type {
  MeasurementPlotStream,
  MeasurementPlotStreamDerived,
  MeasurementPlotStreamRaw
} from '../types';

export const fetchStreamOptions = async ({
  organizationId,
  projectId
}: Pick<RouteParams, 'organizationId' | 'projectId'>) => {
  const algorithms = await fetchAlgorithms({ organizationId, projectId });
  // Issue: #1187
  // The project of type project/twosensor has both the "person/activity_ex" and "person/activity_2s" algorithms so it shows two plots.
  // Always show only 1 derived plot. Always use the headline "Activity" (da):"Aktivitet"
  const derivedOptions: MeasurementPlotStreamDerived[] = algorithms
    /* eslint-disable-next-line unused-imports/no-unused-vars */
    .sort((a, b) => {
      if (a.name === 'person/activity2s') return 1;
      return 0;
    })
    .slice(0, 1)
    .map(item => `derived:${item.name}` as MeasurementPlotStreamDerived);

  const rawOptions: MeasurementPlotStreamRaw[] = [
    'raw:acc/3ax/4g',
    'raw:temp/acc/scalar',
    'raw:volt/system/mv'
  ];
  const streamOptions: MeasurementPlotStream[] = [
    'derived:chunk/activity',
    ...rawOptions
  ];
  return streamOptions;
};
