export const colors = {
  'activity/upright_bike/time': '#FF0000',
  'activity/upright_run/time': '#3dff00',
  'activity/upright_fastwalk/time': '#FF0000',
  'activity/upright_walk/time': '#F0AD4E',
  'activity/upright_moderate/time': '#42cbb2',
  'activity/upright_sporadic_walk/time': '#d7e44e',
  'activity/upright_stand/time': '#2a88ef',
  'activity/sitting/time': '#a2c6de',
  'activity/sitting_movement/time': '#d18ff1',
  'activity/lying_sitting/time': '#dddddd',
  'activity/lying_sitting_rest/time': '#dddddd',
  'activity/lying_rest/time': '#dddddd',
  'activity/lying_sitting_movement/time': '#c366ea',
  'activity/lying_movement/time': '#c366ea',
  'activity/lying/time': '#dddddd',
  'general/nodata/time': '#ffccaa',
  'activity/exercise/time': '#3dff00',
  'activity/cycling/time': '#009100',
  'activity/sleep_no_movement/time': '#6b6b6b',
  'activity/sleep_movement/time': '#929292',
  'activity/sleep_active/time': '#d0a77e',

  // Demos
  'activity/calm_lying/time': '#58A4EF',
  'activity/restless_lying/time': '#4561BE',
  'activity/calm_sitting/time': '#8ED74F',
  'activity/restless_sitting/time': '#28B44F',
  'activity/walking/time': '#F0AD4E',
  'activity/other/time': '#555555',

  // Acti4
  'activity/upright_move/time': '#1d60aa',
  'activity/upright_stair/time': '#FF0000',
  'activity/row/time': '#3dff00',
  'activity/non_wear/time': '#666666'
};

export const heights = {
  'activity/other/time': 1.5,
  'activity/exercise/time': 4,
  'activity/cycling/time': 4,
  'activity/sleep_no_movement/time': 1.0,
  'activity/sleep_movement/time': 2.0,
  'activity/sleep_active/time': 3.0,
  'activity/walking/time': 3,
  'activity/upright_bike/time': 4,
  'activity/upright_run/time': 4,
  'activity/upright_fastwalk/time': 3,
  'activity/restless_sitting/time': 2,
  'activity/calm_sitting/time': 1,
  'activity/upright_walk/time': 3,
  'activity/upright_moderate/time': 3.5,
  'activity/upright_sporadic_walk/time': 3,
  'activity/upright_stand/time': 2,
  'activity/sitting/time': 1.5,
  'activity/sitting_movement/time': 2,
  'activity/restless_lying/time': 2,
  'activity/calm_lying/time': 1,
  'activity/lying_sitting/time': 1,
  'activity/lying_sitting_rest/time': 1,
  'activity/lying_rest/time': 1,
  'activity/lying_sitting_movement/time': 2,
  'activity/lying_movement/time': 2,
  'activity/lying/time': 1,
  'general/nodata/time': 1,

  // Acti4
  'activity/upright_move/time': 3,
  'activity/upright_stair/time': 3,
  'activity/row/time': 3,
  'activity/non_wear/time': 1
};

export const fallbackColor = '#000000';
