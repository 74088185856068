const routes = {
  login: () => '/r/login',
  logout: () => '/r/logout',
  reset: () => '/r/reset',
  forgotPassword: () => '/r/forgot-password',
  verifyConfirmationCode: () => '/r/verify-confirmation-code',
  resetPassword: () => '/r/reset-password',
  settings: () => '/r/settings',
  ble: () => '/r/ble',
  // ---
  organizations: () => '/r/o',
  projects: organizationId => `/r/o/${organizationId}/p`,
  // ---
  sensors: (organizationId, projectId) =>
    `/r/o/${organizationId}/p/${projectId}/sensors`,
  sensorDetails: (organizationId, projectId, sensorId) =>
    `/r/o/${organizationId}/p/${projectId}/sensors/${sensorId}`,
  // ---
  simpleSensors: (organizationId, projectId) =>
    `/r/o/${organizationId}/p/${projectId}/simple-sensors`,
  simpleSensorDetails: (organizationId, projectId, sensorId) =>
    `/r/o/${organizationId}/p/${projectId}/simple-sensors/${sensorId}`,
  // ---
  patients: (organizationId, projectId) =>
    `/r/o/${organizationId}/p/${projectId}/patients`,
  patientDetails: (organizationId, projectId, patientId) =>
    `/r/o/${organizationId}/p/${projectId}/patients/${patientId}`,
  // ---
  browseData: (organizationId, projectId) =>
    `/r/o/${organizationId}/p/${projectId}/browse-data`,
  // ---
  iSpinePatients: (organizationId, projectId) =>
    `/r/o/${organizationId}/p/${projectId}/iSpine-patients`,
  iSpinePatientDetails: (organizationId, projectId, measurementId) =>
    `/r/o/${organizationId}/p/${projectId}/iSpine-patients/${measurementId}`,
  // ---
  hospitalPatients: (organizationId, projectId) =>
    `/r/o/${organizationId}/p/${projectId}/hospital-patients`,
  // ---
  externalPatients: (organizationId, projectId) =>
    `/r/o/${organizationId}/p/${projectId}/external_overview1`,
  // --- Admin
  gateways: () => '/r/admin/gateways',
  gatewayInfo: gatewayId => `/r/admin/gateways/${gatewayId}`,
  gatewayDetails: gatewayId => `/r/admin/gateways/${gatewayId}/details`,
  gatewayReports: gatewayId => `/r/admin/gateways/${gatewayId}/reports`,
  gatewayNearbySensors: gatewayId =>
    `/r/admin/gateways/${gatewayId}/nearby-sensors`,
  gatewayNearbySensorDetails: (gatewayId, sensorId) =>
    `/r/admin/gateways/${gatewayId}/nearby-sensors/${sensorId}`,
  // ---
  // iCompression
  // ---
  iCompDepartments: () => `/r/h`,
  iCompSensors: (hospitalId, departmentId, projectId) =>
    `/r/h/${hospitalId}/d/${departmentId}/p/${projectId}/sensors`,
  iCompSensorDetails: (hospitalId, departmentId, projectId, sensorId) =>
    `/r/h/${hospitalId}/d/${departmentId}/p/${projectId}/sensors/${sensorId}`,
  iCompPatients: (hospitalId, departmentId, projectId) =>
    `/r/h/${hospitalId}/d/${departmentId}/p/${projectId}/patients`,
  iCompPatientAdd: (hospitalId, departmentId, projectId) =>
    `/r/h/${hospitalId}/d/${departmentId}/p/${projectId}/patients/create`,
  iCompPatientPlots: (hospitalId, departmentId, projectId, patientId) =>
    `/r/h/${hospitalId}/d/${departmentId}/p/${projectId}/patients/${patientId}/plots`,
  // ---
  iCompAdmin: () => `/r/admin`,
  // ---
  iCompAdminUserOverview: hospitalId => `/r/admin/h/${hospitalId}/users`,
  iCompAdminUserProfile: (hospitalId, userId) =>
    `/r/admin/h/${hospitalId}/users/${userId}`,
  iCompAdminUserRegistration: hospitalId =>
    `/r/admin/h/${hospitalId}/user-registration`,
  // ---
  iCompAdminSensorOverview: hospitalId => `/r/admin/h/${hospitalId}/sensors`,
  // ---
  iCompAdminDepartmentProfile: (hospitalId, departmentId) =>
    `/r/admin/h/${hospitalId}/d/${departmentId}`,
  iCompAdminDepartmentUserOverview: (hospitalId, departmentId) =>
    `/r/admin/h/${hospitalId}/d/${departmentId}/users`,
  iCompAdminDepartmentUserProfile: (hospitalId, departmentId, userId) =>
    `/r/admin/h/${hospitalId}/d/${departmentId}/users/${userId}`,
  iCompAdminDepartmentUserRegistration: (hospitalId, departmentId) =>
    `/r/admin/h/${hospitalId}/d/${departmentId}/user-registration`,
  iCompAdminDepartmentSensorOverview: (hospitalId, departmentId) =>
    `/r/admin/h/${hospitalId}/d/${departmentId}/sensors`,
  // ---
  // NFA
  // ---
  nfaOrganizations: () => '/r/o',
  nfaProjects: organizationId => `/r/o/${organizationId}/p`,
  nfaSubjects: (organizationId, projectId) =>
    `/r/o/${organizationId}/p/${projectId}/subjects`,
  nfaSensors: (organizationId, projectId) =>
    `/r/o/${organizationId}/p/${projectId}/sensors`,
  nfaSensorDetails: (organizationId, projectId, sensorId) =>
    `/r/o/${organizationId}/p/${projectId}/sensors/${sensorId}`
};

export default routes;
