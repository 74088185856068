import { axiosClient } from 'api/axios-client';
import { fetchDownloadToken } from 'api/download-token';
import type { ExportQueueEntry } from 'api/export';
import type { RouteParams } from 'app/types';
import type { MeasurementExportLang } from '../types';

type Params = Pick<
  RouteParams,
  'organizationId' | 'projectId' | 'patientId'
> & {
  lang: MeasurementExportLang;
};

type ExportAction = 'modal' | 'url';

export const exportWeekReport = async (
  { organizationId, projectId, patientId, lang }: Params,
  exportAction: ExportAction
) => {
  if (exportAction === 'modal') {
    const payload = {
      scope_id: organizationId,
      project_id: projectId,
      patient_id: patientId,
      lang,
      report_class: 'report/standard'
    };
    const response: { value: { queue_entry: ExportQueueEntry } } =
      await axiosClient
        .post(`/api/1.0/export/measurement/report`, payload)
        .then(response => response.data);
    return response.value.queue_entry;
  }
  // exportAction === 'url'
  else {
    const downloadToken = await fetchDownloadToken();
    const params = {
      scope_id: organizationId,
      project_id: projectId,
      patient_id: patientId,
      lang,
      report_class: 'report/standard',
      dt: downloadToken
    };
    const exportUrl = axiosClient.getUri({
      url: '/api/1.0/export/measurement/report',
      params
    });
    return exportUrl;
  }
};
