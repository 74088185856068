import React from 'react';
import PropTypes from 'prop-types';
import Dash from 'components/dash';
import { defaultFormat, formats, useFormatDate } from './use-format-date';

export const FormatDate = React.memo(({ value, format }) => {
  const formatDate = useFormatDate();
  if (value === null || value === undefined || !value) return <Dash />;
  return <span>{formatDate(value, format)}</span>;
});

FormatDate.propTypes = {
  value: PropTypes.string,
  format: PropTypes.oneOf(formats)
};

FormatDate.defaultProps = {
  format: defaultFormat
};
