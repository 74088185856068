import React from 'react';
import PropTypes from 'prop-types';
import Dash from 'components/dash';
import { getFullName } from 'utils/patient';

const getPatientAttachedSortValue = data => {
  const { attachedPatients } = data;
  const len = attachedPatients?.length;
  if (len > 0) {
    return getFullName(attachedPatients[0]);
  }
  return '';
};

const PatientAttached = ({ data: { attachedPatients } }) => {
  const len = attachedPatients?.length;
  if (!len) {
    return <Dash />;
  }

  if (len === 1) {
    return getFullName(attachedPatients[0]);
  }

  return `${getFullName(attachedPatients[0])} +${len - 1}`;
};

PatientAttached.propTypes = {
  data: PropTypes.object.isRequired
};

export { getPatientAttachedSortValue };
export default PatientAttached;
