import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import routes from 'routes';
import Table from 'components/table';
import { columns } from './configs';

const SimpleSensorDashboardTableInfo = props => {
  const params = useParams();
  const navigate = useNavigate();

  const onRowClick = useCallback(
    rowData => {
      const { id: sensorId } = rowData;
      const { organizationId, projectId } = params;
      const path = routes.simpleSensorDetails(
        organizationId,
        projectId,
        sensorId
      );
      navigate(path);
    },
    [params, navigate]
  );

  return (
    <Table
      {...props}
      columns={columns}
      defaultSortColumn="status"
      defaultSortDirection="descending"
      onRowClick={onRowClick}
    />
  );
};

SimpleSensorDashboardTableInfo.propTypes = {
  fetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  data: PropTypes.array
};

export default SimpleSensorDashboardTableInfo;
