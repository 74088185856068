import React from 'react';
import PropTypes from 'prop-types';
import AccelerometerDay from './accelerometer/day';
import AccelerometerHour from './accelerometer/hour';
import BatteryDay from './battery/day';
import BatteryHour from './battery/hour';
import CapacitanceDay from './capacitance/day';
import CapacitanceHour from './capacitance/hour';
import DerivedDay from './derived/day';
import DerivedHour from './derived/hour';
import Chart from './elements/chart';
import PostureAngleDay from './posture-angle/day';
import PostureAngleHour from './posture-angle/hour';
import TemperatureDay from './temperature/day';
import TemperatureHour from './temperature/hour';

const MapAccelerometer = {
  day: AccelerometerDay,
  hour: AccelerometerHour
};
const MapTemperature = {
  day: TemperatureDay,
  hour: TemperatureHour
};
const MapBattery = {
  day: BatteryDay,
  hour: BatteryHour
};
const MapCapacitance = {
  day: CapacitanceDay,
  hour: CapacitanceHour
};
const MapPostureAngle = {
  day: PostureAngleDay,
  hour: PostureAngleHour
};
const MapDerived = {
  day: DerivedDay,
  hour: DerivedHour
};

const PlotVictory = ({
  type,
  stream,
  date,
  values,
  timezone,
  onSelectTick
}) => {
  switch (stream) {
    case 'raw:acc/3ax/4g': {
      const Accelerometer = MapAccelerometer[type];
      return (
        <Accelerometer
          date={date}
          timezone={timezone}
          streams={values.streams}
          onSelectTick={onSelectTick}
        />
      );
    }

    case 'raw:temp/acc/scalar': {
      const Temperature = MapTemperature[type];
      return (
        <Temperature
          date={date}
          timezone={timezone}
          streams={values.streams}
          onSelectTick={onSelectTick}
        />
      );
    }

    case 'raw:volt/system/mv': {
      const Battery = MapBattery[type];
      return (
        <Battery
          date={date}
          timezone={timezone}
          streams={values.streams}
          onSelectTick={onSelectTick}
        />
      );
    }

    case 'raw:cap/stretch/scalar': {
      const Capacitance = MapCapacitance[type];
      return (
        <Capacitance
          date={date}
          timezone={timezone}
          streams={values.streams}
          onSelectTick={onSelectTick}
        />
      );
    }

    case 'raw:acc/x/angle': {
      const PostureAngle = MapPostureAngle[type];
      return (
        <PostureAngle
          date={date}
          streams={values.streams}
          onSelectTick={onSelectTick}
        />
      );
    }

    case 'derived:chunk/freq1': {
      const PostureAngle = MapPostureAngle[type];
      return (
        <PostureAngle
          date={date}
          timezone={timezone}
          streams={values.streams}
          categories={values.categories}
          onSelectTick={onSelectTick}
        />
      );
    }

    default: {
      const Derived = MapDerived[type];
      return (
        <Derived
          date={date}
          timezone={timezone}
          streams={values.streams}
          categories={values.categories}
          onSelectTick={onSelectTick}
        />
      );
    }
  }
};

PlotVictory.propTypes = {
  type: PropTypes.oneOf(['day', 'hour']).isRequired,
  date: PropTypes.string.isRequired,
  stream: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  timezone: PropTypes.string,
  onSelectTick: PropTypes.func
};

export { Chart };

export default PlotVictory;
