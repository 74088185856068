import React from 'react';
import { useTranslate } from 'intl';
import { Icon, Dropdown } from 'semantic-ui-react';
import {
  exportActivityData,
  exportDiary,
  exportCSV,
  exportCSVDetailed,
  exportCSVSummary
} from 'api/nfa-subjects';
import { useBulkExport } from '../../../bulk-export-modal/use-bulk-export';
import { isDisabledExport } from '../../../utils';
import { SendNotifButton } from '../send-notif-button';
import styles from './styles.module.scss';

const stopPropagation = e => e.stopPropagation();

export const Actions = ({
  data,
  setSendNotifData,
  setEditData,
  setDeleteData
}) => {
  const t = useTranslate('nfa-subjects');

  const { onExport: onExportActivity } = useBulkExport({
    exportAction: 'modal',
    apiExport: exportActivityData,
    subjects: [data],
    onCompleted: () => {}
  });
  const { onExport: onExportDiary } = useBulkExport({
    exportAction: 'modal',
    apiExport: exportDiary,
    subjects: [data],
    onCompleted: () => {}
  });
  const { onExport: onExportCSV } = useBulkExport({
    exportAction: 'modal',
    apiExport: exportCSV,
    subjects: [data],
    onCompleted: () => {}
  });
  const { onExport: onExportCSVDetailed } = useBulkExport({
    exportAction: 'modal',
    apiExport: exportCSVDetailed,
    subjects: [data],
    onCompleted: () => {}
  });
  const { onExport: onExportCSVSummary } = useBulkExport({
    exportAction: 'modal',
    apiExport: exportCSVSummary,
    subjects: [data],
    onCompleted: () => {}
  });

  return (
    <div className={styles.wrapper} onClick={stopPropagation}>
      <SendNotifButton data={data} setSendNotifData={setSendNotifData} />

      <div className={styles.actions}>
        <Dropdown icon={<Icon name="ellipsis vertical" color="grey" />}>
          <Dropdown.Menu>
            <Dropdown.Item
              disabled={isDisabledExport(data)}
              onClick={() => onExportActivity()}
            >
              {t('export-activity-data')}
            </Dropdown.Item>
            <Dropdown.Item
              disabled={isDisabledExport(data)}
              onClick={() => onExportDiary()}
            >
              {t('export-diary')}
            </Dropdown.Item>
            <Dropdown.Item
              disabled={isDisabledExport(data)}
              onClick={() => onExportCSV()}
            >
              {t('export-csv')}
            </Dropdown.Item>
            <Dropdown.Item
              disabled={isDisabledExport(data)}
              onClick={() => onExportCSVDetailed()}
            >
              {t('export-csv-detailed')}
            </Dropdown.Item>
            <Dropdown.Item
              disabled={isDisabledExport(data)}
              onClick={() => onExportCSVSummary()}
            >
              {t('export-csv-summary')}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setEditData(data)}>
              {t('edit-subject')}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setDeleteData(data)}>
              {t('delete-subject')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
