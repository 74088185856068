/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
// *NOTE*:
// Elements must be used as a normal function instead of render JSX component
// Because VictoryChart inspects the props of its children directly
// If we wrap Victory Elements (VictoryLine, VictoryAxis ...) inside another component
// we must merge other props passed from parent, and the merge will be very complex for some cases
// See: https://github.com/FormidableLabs/victory/issues/938
import { Area, AxisX, AxisY, Chart, Line, ZoomIn, ZoomOut } from '../elements';
import { useZoom } from '../hooks';
import utils from '../utils/hour';

const AccelerometerHour = ({ date, timezone, streams }) => {
  const t = useTranslate('plot');
  const { zoomId, onZoomIn, onZoomOut } = useZoom();

  const zoomed = zoomId !== undefined;

  const domain = {
    x: utils.getXDomain(date, timezone, zoomId),
    y: [-2, 2]
  };

  const xTicks = utils.getXTicks(domain, zoomed);
  const yTicks = [-2, -1, 0, 1, 2];

  const datax = [];
  const datay = [];
  const dataz = [];
  const dataxd = [];
  const datayd = [];
  const datazd = [];
  const count = streams.ts.length;
  if (zoomed) {
    for (let i = 0; i < count; i++) {
      if (streams.ts[i] > domain.x[0] && streams.ts[i] < domain.x[1]) {
        datax.push({ x: streams.ts[i], y: streams.x[i] });
        datay.push({ x: streams.ts[i], y: streams.y[i] });
        dataz.push({ x: streams.ts[i], y: streams.z[i] });
      }
    }
  } else {
    for (let i = 0; i < count; i += 100) {
      let avg_x = 0;
      let cnt = 0;
      let min_x = streams.x[i];
      let max_x = streams.x[i];
      let avg_y = 0;
      let min_y = streams.y[i];
      let max_y = streams.y[i];
      let avg_z = 0;
      let min_z = streams.z[i];
      let max_z = streams.z[i];
      for (let j = 0; j < 100; j++) {
        let x = streams.x[i + j];
        avg_x += x;
        if (x < min_x) {
          min_x = x;
        } else if (x > max_x) {
          max_x = x;
        }
        let y = streams.y[i + j];
        avg_y += y;
        if (y < min_y) {
          min_y = y;
        } else if (y > max_y) {
          max_y = y;
        }
        let z = streams.z[i + j];
        avg_z += z;
        if (z < min_z) {
          min_z = z;
        } else if (z > max_z) {
          max_z = z;
        }
        cnt += 1;
      }
      datax.push({ x: streams.ts[i], y: avg_x / cnt });
      dataxd.push({ x: streams.ts[i], y: min_x, y0: max_x });
      datay.push({ x: streams.ts[i], y: avg_y / cnt });
      datayd.push({ x: streams.ts[i], y: min_y, y0: max_y });
      dataz.push({ x: streams.ts[i], y: avg_z / cnt });
      datazd.push({ x: streams.ts[i], y: min_z, y0: max_z });
    }
  }

  return (
    <Chart domain={domain}>
      {AxisX({
        tickValues: xTicks,
        timezone,
        zoomed,
        label: zoomed ? t('time-in-seconds') : t('time-in-minutes')
      })}
      {AxisY({ tickValues: yTicks, label: 'g' })}

      {Line({ data: datax, stroke: '#FF0000' })}
      {Area({ data: dataxd, fill: '#FF000030' })}

      {Line({ data: datay, stroke: '#00FF00' })}
      {Area({ data: datayd, fill: '#00FF0030' })}

      {Line({ data: dataz, stroke: '#0000FF' })}
      {Area({ data: datazd, fill: '#0000FF30' })}

      {zoomed
        ? ZoomOut({
            domain,
            onSelectTick: onZoomOut
          })
        : ZoomIn({
            range: 60 * 4,
            xstep: 60 * 1000,
            xcount: 60,
            domain,
            timezone,
            onSelectTick: onZoomIn
          })}
    </Chart>
  );
};

AccelerometerHour.propTypes = {
  date: PropTypes.string.isRequired,
  timezone: PropTypes.string,
  streams: PropTypes.object.isRequired
};

export default AccelerometerHour;
