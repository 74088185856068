import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Table } from 'semantic-ui-react';

const unsortableStyles = {
  pointerEvents: 'none',
  cursor: 'default'
};

const fitContentStyles = {
  width: '0',
  minWidth: 'fit-content'
};

const TableHeader = ({
  columns,
  sorted,
  direction,
  onSort,
  data: _data,
  selectable,
  selectableData,
  selectedData,
  onSelect
}) => {
  const data = selectableData || _data;
  const isSelectedAll =
    selectable && data.every(item => selectedData.find(s => s.id === item.id));

  return (
    <Table.Row>
      {selectable && (
        <Table.HeaderCell width={1} textAlign="center">
          <Checkbox
            checked={isSelectedAll}
            onClick={() => (isSelectedAll ? onSelect([]) : onSelect(data))}
          />
        </Table.HeaderCell>
      )}
      {columns.map(config => {
        return (
          <Table.HeaderCell
            key={config.name}
            width={config.width || null}
            textAlign={config.align || null}
            sorted={sorted === config.name ? direction : null}
            onClick={config.sortable ? onSort.bind(null, config.name) : null}
            style={{
              ...(!config.sortable ? unsortableStyles : null),
              ...(config.fitContent ? fitContentStyles : null)
            }}
          >
            {config.header}
          </Table.HeaderCell>
        );
      })}
    </Table.Row>
  );
};

TableHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  sorted: PropTypes.string,
  direction: PropTypes.string,
  onSort: PropTypes.func
};

export default TableHeader;
