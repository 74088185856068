import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { exportAccelerometerCsv } from 'api/measurements';
import { useBulkExport } from './use-bulk-export';

const ExportAccelerometerCsv = ({
  patients,
  disabled,
  onCompleted,
  children
}) => {
  const [exporting, onExport] = useBulkExport({
    exportAction: 'modal',
    apiExport: exportAccelerometerCsv,
    patients,
    onCompleted
  });

  return (
    <Button
      primary
      compact
      icon="download"
      content={children}
      loading={exporting}
      disabled={disabled}
      onClick={onExport}
    />
  );
};

ExportAccelerometerCsv.propTypes = {
  patients: PropTypes.array.isRequired
};

export default ExportAccelerometerCsv;
