import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
import { Form, Header, Modal } from 'semantic-ui-react';
import Box from 'components/box';
import ColoredDatePicker from 'components/colored-date-picker';
import DatePicker from 'components/date-picker';
import ErrorMessage from 'components/error-message';

const SensorPlotsExportModal = ({
  open,
  // ---
  exportType,
  inputType,
  // ---
  limit,
  // ---
  startDate,
  onChangeStartDate,
  endDate,
  onChangeEndDate,
  dayStatistics,
  minDate,
  maxDate,
  // ---
  exportFormat,
  onChangeExportFormat,
  showExportFormat,
  // ---
  onExport,
  onCancel,
  exporting,
  error
}) => {
  const t = useTranslate('sensor');

  return (
    <Modal open={open} size="small">
      <Modal.Header>{t(`export-${exportType}`)}</Modal.Header>
      <Modal.Content>
        <Form onSubmit={onExport} error={error}>
          <Box mb="1em">
            <Header as="h4" style={{ margin: '0px' }}>
              {t('select-export-period')}
            </Header>
            <p>
              {limit.value === 91 && limit.unit === 'day'
                ? t('limit-3-months')
                : t('limit', {
                    limit: t(`limit-${limit.unit}`, {
                      value: limit.value
                    })
                  })}
            </p>
          </Box>

          <Form.Group widths="equal">
            <Form.Field required>
              <label>{t('from')}</label>
              {dayStatistics ? (
                <ColoredDatePicker
                  type={inputType}
                  value={startDate}
                  onChange={onChangeStartDate}
                  dayStatistics={dayStatistics}
                />
              ) : (
                <DatePicker
                  type={inputType}
                  value={startDate}
                  onChange={onChangeStartDate}
                />
              )}
            </Form.Field>
            <Form.Field required>
              <label>{t('to')}</label>
              {dayStatistics ? (
                <ColoredDatePicker
                  type={inputType}
                  value={endDate}
                  onChange={onChangeEndDate}
                  minDate={minDate}
                  maxDate={maxDate}
                  dayStatistics={dayStatistics}
                />
              ) : (
                <DatePicker
                  type={inputType}
                  value={endDate}
                  onChange={onChangeEndDate}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              )}
            </Form.Field>
          </Form.Group>

          {showExportFormat && (
            <div>
              <div style={{ marginTop: '2em', marginBottom: '1em' }}>
                <Header as="h4" style={{ margin: '0px' }}>
                  {t('select-export-format')}
                </Header>
              </div>

              <Form.Group inline>
                <Form.Radio
                  label="Hex"
                  name="radioGroup"
                  value="hex"
                  checked={exportFormat === 'hex'}
                  onChange={onChangeExportFormat}
                />
                <Form.Radio
                  label="Binary"
                  name="radioGroup"
                  value="bin"
                  checked={exportFormat === 'bin'}
                  onChange={onChangeExportFormat}
                />
              </Form.Group>
            </div>
          )}

          {error && (
            <div style={{ marginTop: '2rem' }}>
              <ErrorMessage error={error} />
            </div>
          )}

          <Form.Group style={{ marginTop: '3rem' }}>
            <Form.Button
              type="button"
              onClick={onCancel}
              content={t('common:cancel')}
              style={{ margin: 0 }}
            />
            <Form.Button
              type="submit"
              color="blue"
              loading={exporting}
              content={t('export')}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

SensorPlotsExportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  // ---
  exportType: PropTypes.oneOf(['PDF', 'CSV', 'Raw']).isRequired,
  inputType: PropTypes.oneOf(['date', 'date-time']).isRequired,
  // ---
  limit: PropTypes.shape({
    value: PropTypes.number.isRequired,
    unit: PropTypes.oneOf(['day', 'hour']).isRequired
  }).isRequired,
  // ---
  startDate: PropTypes.string.isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  // ---
  exportFormat: PropTypes.string,
  onChangeExportFormat: PropTypes.func,
  showExportFormat: PropTypes.bool,
  // ---
  endDate: PropTypes.string.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,
  // ---
  onExport: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  exporting: PropTypes.bool,
  error: PropTypes.object
};

export default SensorPlotsExportModal;
