import React, { forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes, { string } from 'prop-types';
import { useIntl, useTranslate } from 'intl';
import { Input } from 'semantic-ui-react';
import { fakeLocalAtTimezone, revertFakeLocalAtTimezone } from 'utils/date';
import './styles.css';

const dateFormats = {
  date: {
    en: 'MM/dd/yyyy',
    da: 'dd.MM.yyyy'
  },
  'date-time': {
    en: 'MM/dd/yyyy HH:mm',
    da: 'dd.MM.yyyy HH:mm'
  }
};

const DatePicker = ({
  name,
  type,
  timezone,
  'data-cy': dataCy,
  value: _value,
  onChange: _onChange,
  timeIntervals,
  minDate,
  maxDate,
  renderValue,
  renderDayContents,
  placeholder,
  isClearable,
  disabled,
  ...otherProps
}) => {
  const intl = useIntl();
  const t = useTranslate('common');

  const value = _value
    ? new Date(timezone ? fakeLocalAtTimezone(_value, timezone) : _value)
    : null;

  const onChange = v => {
    _onChange(null, {
      // eslint-disable-next-line no-nested-ternary
      value: v
        ? timezone
          ? revertFakeLocalAtTimezone(v.toISOString(), timezone)
          : v.toISOString()
        : null
    });
  };

  const CustomInput = forwardRef(({ value, onChange, onClick }, ref) => {
    return (
      <Input
        icon="calendar"
        iconPosition="left"
        data-cy={dataCy}
        onClick={onClick}
        ref={ref}
        value={value && renderValue ? renderValue(value) : value}
        onChange={onChange}
        placeholder={placeholder || t('select-date')}
        disabled={disabled}
      />
    );
  });

  return (
    <ReactDatePicker
      {...otherProps}
      id={dataCy}
      name={name}
      selected={value}
      onChange={onChange}
      customInput={<CustomInput />}
      dateFormat={dateFormats[type][intl.language]}
      minDate={minDate ? new Date(minDate) : null}
      maxDate={maxDate ? new Date(maxDate) : null}
      showTimeSelect={type === 'date-time'}
      filterTime={
        type === 'date-time' && minDate && maxDate
          ? time => {
              const minTime = new Date(minDate).getTime();
              const maxTime = new Date(maxDate).getTime();
              const currentTime = new Date(time).getTime();
              return currentTime >= minTime && currentTime <= maxTime;
            }
          : undefined
      }
      timeFormat="HH:mm"
      timeIntervals={timeIntervals}
      renderDayContents={renderDayContents}
      isClearable={isClearable}
      fixedHeight
    />
  );
};

DatePicker.defaultProps = {
  type: 'date',
  timeIntervals: 15
};

DatePicker.propTypes = {
  type: PropTypes.oneOf(['date', 'date-time']),
  timeIntervals: PropTypes.number,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  placeholder: string,
  isClearable: PropTypes.bool,
  disabled: PropTypes.bool
};

export default DatePicker;
