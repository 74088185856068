/* eslint-disable consistent-return */
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';

const init = initialForm => ({
  form: initialForm,
  validations: null,
  submitting: false,
  error: null,
  success: null
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'change':
      return {
        ...state,
        error: null,
        validations: null,
        form: {
          ...state.form,
          [action.payload.key]: action.payload.value
        }
      };
    case 'validate':
      return {
        ...state,
        validations: action.payload
      };
    case 'submit':
      return {
        ...state,
        submitting: true,
        error: null
      };
    case 'success':
      return {
        ...state,
        submitting: false,
        success: true
      };
    case 'error':
      return {
        ...state,
        submitting: false,
        error: action.payload
      };
    case 'clear-error': {
      return {
        ...state,
        error: null
      };
    }
    case 'reset':
      return init(action.payload);
    default:
      throw new Error();
  }
};

const useForm = (
  { initialForm, validates, apiSubmit, onSuccess, onError },
  dependencies = []
) => {
  const [state, dispatch] = useReducer(reducer, initialForm, init);

  const onChanges = useMemo(() => {
    return Object.keys(initialForm).reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: value => {
          dispatch({
            type: 'change',
            payload: { key: curr, value }
          });
        }
      }),
      {}
    );
  }, []);

  const formRef = useRef();
  useEffect(() => {
    formRef.current = state.form;
  });

  const onSubmit = useCallback(
    event => {
      if (event && event.preventDefault) event.preventDefault();

      const validations = {};
      if (validates) {
        Object.keys(validates).forEach(field => {
          const validate = validates[field];
          const validation = validate(formRef.current);
          if (validation) {
            validations[field] = validation;
          }
        });
      }

      if (Object.keys(validations).length) {
        dispatch({ type: 'validate', payload: validations });
        return;
      }

      dispatch({ type: 'submit' });

      return apiSubmit(formRef.current)
        .then(response => {
          dispatch({ type: 'success' });
          if (onSuccess) {
            onSuccess(response);
          }
          return response;
        })
        .catch(error => {
          dispatch({
            type: 'error',
            payload: error
          });
          if (onError) {
            onError(error);
          }
          return Promise.reject(error);
        });
    },
    [...dependencies]
  );

  const onReset = useCallback(() => {
    dispatch({ type: 'reset', payload: initialForm });
  }, []);

  const onClearError = useCallback(() => {
    dispatch({ type: 'clear-error' });
  }, []);

  return {
    ...state,
    onChanges,
    onSubmit,
    onReset,
    onClearError
  };
};

export default useForm;
