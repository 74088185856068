import { axiosClient } from 'api/axios-client';
import type { NetworkError } from 'app/types';
import type {
  LoginForm,
  LoginPayload,
  LoginResponse,
  LoginResponseRaw,
  LoginWithTwoFaForm,
  RequestTwoFaCodeResponse
} from './types';

export const login = async ({
  email,
  password,
  sessionKey
}: LoginForm): Promise<LoginResponse> => {
  const payload: LoginPayload = {
    user_email: email,
    password: password,
    session_key: sessionKey
  };

  try {
    const response = await axiosClient
      .post<{ value: LoginResponseRaw }>('/api/1.0/auth/login', payload)
      .then(raw => {
        axiosClient.clearCache();
        const { auth_token, session_key } = raw.data.value;
        const response: LoginResponse = {
          authToken: auth_token,
          sessionKey: session_key
        };
        return response;
      });
    return response;
  } catch (_e) {
    const error = _e as NetworkError;
    return Promise.reject(error);
  }
};

export const requestTwoFaCode = async ({
  email,
  password,
  sessionKey
}: LoginForm) => {
  return axiosClient
    .post<{ value: RequestTwoFaCodeResponse }>('/api/1.0/auth/login', {
      user_email: email,
      password: password,
      session_key: sessionKey,
      browser_information: 'UT browser',
      two_factor_method: 'method/email'
    })
    .then(raw => {
      const { auth_token, session_key } = raw.data.value;
      const response: LoginResponse = {
        authToken: auth_token,
        sessionKey: session_key
      };
      return response;
    });
};

export const loginWithTwoFaCode = async ({
  email,
  password,
  sessionKey,
  twoFaCode
}: LoginWithTwoFaForm) => {
  return axiosClient
    .post<{ value: LoginResponseRaw }>('/api/1.0/auth/login', {
      user_email: email,
      password: password,
      session_key: sessionKey,
      browser_information: 'UT browser',
      two_factor_pin: twoFaCode
    })
    .then(raw => {
      const { auth_token, session_key } = raw.data.value;
      const response: LoginResponse = {
        authToken: auth_token,
        sessionKey: session_key
      };
      return response;
    });
};
