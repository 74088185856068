import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'components/date-picker';
import './styles.css';

const ColoredDatePicker = ({ dayStatistics, ...otherProps }) => {
  return (
    <DatePicker
      {...otherProps}
      // ---
      renderDayContents={(day, date) => {
        if (!dayStatistics) return day;

        const dateKey = moment(date).format('YYYY-MM-DDTHH:mm:ss');
        const dayStatistic = dayStatistics[dateKey];

        if (!dayStatistic) return day;

        if (dayStatistic.status === 'data_status/no_data') {
          return <div className="react-datepicker__day-no-data">{day}</div>;
        }

        if (dayStatistic.status === 'data_status/missing_data') {
          return (
            <div className="react-datepicker__day-colored react-datepicker__day-missing-data">
              <div className="react-datepicker__day-colored-tint" />
              <div
                className="react-datepicker__day-colored-shade"
                style={{ opacity: dayStatistic.intensity * 0.01 }}
              />
              <div className="react-datepicker__day-colored-content">{day}</div>
            </div>
          );
        }

        return (
          <div className="react-datepicker__day-colored">
            <div className="react-datepicker__day-colored-tint" />
            <div
              className="react-datepicker__day-colored-shade"
              style={{ opacity: dayStatistic.intensity }}
            />
            <div className="react-datepicker__day-colored-content">{day}</div>
          </div>
        );
      }}
    />
  );
};

ColoredDatePicker.propTypes = {
  timezone: PropTypes.string,
  // ---
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  // ---
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  dayStatistics: PropTypes.object,
  // ---
  disabled: PropTypes.bool
};

export default ColoredDatePicker;
