import moment from 'moment';

export const isExceedDuration = ({
  startTime,
  endTime,
  duration,
  unit
}: {
  startTime: string;
  endTime: string | null | undefined;
  duration: number;
  unit: 'days' | 'hours';
}) => {
  const startDate = moment(startTime);
  const endDate = endTime ? moment(endTime) : moment();
  const diff = endDate.diff(startDate, unit);
  return diff > duration;
};
