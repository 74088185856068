import { createSorter, createSorterByCharacter } from 'utils/sort';
import {
  getLastOnlineSortValue,
  getLastSyncedSortValue,
  getPatientAttachedSortValue
} from './cells';

export const sorters = {
  name: createSorterByCharacter('name'),
  status: createSorter('status'),
  lastOnline: createSorter(getLastOnlineSortValue),
  lastSynced: createSorter(getLastSyncedSortValue),
  runtime: createSorter('runtime'),
  patientAttached: createSorter(getPatientAttachedSortValue)
};
