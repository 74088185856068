import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TimeInput as Input } from 'semantic-ui-calendar-react';
import { fakeLocalAtTimezone, revertFakeLocalAtTimezone } from 'utils/date';

const HOUR_FORMAT = 'HH:mm';

const TimeInput = ({ timezone, value, onChange }) => {
  const handleChange = useCallback(
    (event, { value: hourStr }) => {
      const validator = moment(hourStr, HOUR_FORMAT, true);
      if (!validator.isValid()) return;

      const hourNumber = Number(hourStr.split(':')[0]);
      const date = moment(value).hour(hourNumber).format();
      onChange(event, {
        value: timezone ? revertFakeLocalAtTimezone(date, timezone) : date
      });
    },
    [value, onChange]
  );

  return (
    <Input
      closable
      disableMinute
      animation="none"
      iconPosition="left"
      style={{ width: '100%' }}
      // ---
      value={moment(
        timezone ? fakeLocalAtTimezone(value, timezone) : value
      ).format(HOUR_FORMAT)}
      onChange={handleChange}
    />
  );
};

TimeInput.propTypes = {
  timezone: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default TimeInput;
