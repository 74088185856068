import { axiosClient } from 'api/axios-client';
import { fetchDownloadToken } from 'api/download-token';
import type { ExportQueueEntry } from 'api/export';
import type {
  SensorExportAction,
  SensorExportDataFormat,
  SensorExportPayload
} from '../types';

const createExportCSV =
  (dataFormat: SensorExportDataFormat) =>
  async (
    exportPayload: SensorExportPayload,
    exportAction?: SensorExportAction
  ) => {
    const {
      organizationId,
      projectId,
      sensorId,
      lang,
      startDate,
      endDate,
      stream
    } = exportPayload;

    const [streamType, streamValue] = stream.split(':');

    if (exportAction === 'modal') {
      const payload = {
        scope_id: organizationId,
        project_id: projectId,
        sensor_id: sensorId,
        lang,
        start_time: startDate,
        end_time: endDate,
        ...(streamType === 'derived'
          ? {
              alg_profile: streamValue
            }
          : {}),
        ...(streamType === 'raw'
          ? {
              stream_name: streamValue
            }
          : {}),
        file_format: 'csv',
        data_format: dataFormat
      };
      const response: { value: { queue_entry: ExportQueueEntry } } =
        await axiosClient
          .post(`/api/1.0/export/sensor/${streamType}`, payload)
          .then(response => response.data);
      return response.value.queue_entry;
    }
    // exportAction === 'url'
    else {
      const downloadToken = await fetchDownloadToken();
      const params = {
        scope_id: organizationId,
        project_id: projectId,
        sensor_id: sensorId,
        lang,
        start_time: startDate,
        end_time: endDate,
        ...(streamType === 'derived' ? { alg_profile: streamValue } : {}),
        ...(streamType === 'raw' ? { stream_name: streamValue } : {}),
        data_format: dataFormat,
        dt: downloadToken,
        show: 0
      };
      const exportUrl = axiosClient.getUri({
        url: `/export/sensor/${streamType}`,
        params
      });
      return exportUrl;
    }
  };

export const exportCSVDay = createExportCSV('summary1h');
export const exportCSVHour = createExportCSV('detailed');
