/* eslint-disable */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { VictoryArea } from 'victory';
import { momentTimezone } from 'utils/date';

const ZoomInItem = ({
  index,
  xstart,
  xstep,
  ydomain,
  timezone,
  onSelectTick,
  ...otherProps
}) => {
  const [hightlight, setHightLight] = useState(false);
  const onMouseOver = useCallback(() => setHightLight(true), []);
  const onMouseOut = useCallback(() => setHightLight(false), []);

  const onClick = useCallback(
    (event, datum) => {
      const { x } = datum.data[0];
      const date = momentTimezone(x, timezone).format();
      onSelectTick(date, index);
    },
    [onSelectTick, index]
  );

  return (
    <VictoryArea
      {...otherProps}
      key={'area-' + index}
      data={[
        { x: xstart + index * xstep, y: ydomain[0], y0: ydomain[1] },
        { x: xstart + (index + 1) * xstep, y: ydomain[0], y0: ydomain[1] }
      ]}
      style={{
        data: {
          fill: '#000000',
          fillOpacity: hightlight ? 0.08 : 0.0,
          stroke: '#000000',
          strokeWidth: 0
        }
      }}
      events={[
        {
          target: 'data',
          eventHandlers: {
            onClick: onClick,
            onMouseOver: onMouseOver,
            onMouseOut: onMouseOut
          }
        }
      ]}
    />
  );
};

ZoomInItem.propTypes = {
  index: PropTypes.number.isRequired,
  xstart: PropTypes.number.isRequired,
  xstep: PropTypes.number.isRequired,
  ydomain: PropTypes.array.isRequired,
  timezone: PropTypes.string,
  onSelectTick: PropTypes.func.isRequired
};

export default ZoomInItem;
