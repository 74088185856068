import React from 'react';
import { useFormatPercent, useTranslate } from 'intl';
import {
  faCircleXmark,
  faClock,
  faHourglassHalf
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Progress } from 'semantic-ui-react';
import CancelFile from '../cancel-file';
import DeleteFile from '../delete-file';
import DownloadFile from '../download-file';
import RefreshFile from '../refresh-file';
import styles from './styles.module.scss';

const Actions = ({ data }) => {
  const t = useTranslate('export-controller');
  const formatPercent = useFormatPercent();

  switch (data.status) {
    case 'export_status/completed': {
      return (
        <div className={styles.actions}>
          <div className={styles.download}>
            <DownloadFile data={data} />
          </div>
          <div className={styles.btn1}>
            <RefreshFile data={data} />
          </div>
          <div className={styles.btn2}>
            <DeleteFile data={data} />
          </div>
        </div>
      );
    }
    case 'export_status/queued': {
      return (
        <div className={styles.actions}>
          <div className={styles.queued}>
            <FontAwesomeIcon icon={faClock} />
            <p>{t('queued')}</p>
          </div>
          <div className={styles.btn1}>
            <CancelFile data={data} />
          </div>
          <div className={styles.btn2}>
            <RefreshFile data={data} />
          </div>
        </div>
      );
    }
    case 'export_status/running': {
      return (
        <div className={styles.actions}>
          <div className={styles.progress}>
            <Progress
              className={styles['progress-bar']}
              size="small"
              color="yellow"
              style={{ margin: 0 }}
              percent={data.progress_percent * 100}
            />
            <p className={styles['progress-label']}>
              {formatPercent(data.progress_percent)}
            </p>
          </div>
          <div className={styles.btn1}>
            <CancelFile data={data} />
          </div>
          <div className={styles.btn2}>
            <RefreshFile data={data} />
          </div>
        </div>
      );
    }
    case 'export_status/cancelled': {
      return (
        <div className={styles.actions}>
          <div className={styles.cancelled}>
            <FontAwesomeIcon icon={faCircleXmark} />
            <p>{t('cancelled')}</p>
          </div>
          <div className={styles.btn1}>
            <RefreshFile data={data} />
          </div>
          <div className={styles.btn2}>
            <DeleteFile data={data} />
          </div>
        </div>
      );
    }
    case 'export_status/failed': {
      return (
        <div className={styles.actions}>
          <div className={styles.failed}>
            <FontAwesomeIcon icon={faCircleXmark} />
            <p>{t('failed')}</p>
          </div>
          <div className={styles.btn1}>
            <RefreshFile data={data} />
          </div>
          <div className={styles.btn2}>
            <DeleteFile data={data} />
          </div>
        </div>
      );
    }
    case 'export_status/resource_pending': {
      return (
        <div className={styles.actions}>
          <div className={styles.queued}>
            <FontAwesomeIcon icon={faHourglassHalf} />
            <p>{t('resource_pending')}</p>
          </div>
          <div className={styles.btn1}>
            <CancelFile data={data} />
          </div>
          <div className={styles.btn2}>
            <RefreshFile data={data} />
          </div>
        </div>
      );
    }
    default:
      return null;
  }
};

export default Actions;
