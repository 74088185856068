export { getStartTime, getEndTime } from 'api/patients';

export const migrateForm = (patient, algorithms) => {
  // For `Edit` patient
  if (patient) {
    return {
      firstName: patient.firstName,
      lastName: patient.lastName,
      phoneNumber: patient.phoneNumber,
      timezone: patient.timezone,
      measurementMethod: patient.measurementMethod,
      sensorThigh:
        patient.sensors.find(
          sensor =>
            sensor.place === 'person/thigh' || sensor.place === 'person/back'
        )?.id || '',
      sensorChest:
        patient.sensors.find(sensor => sensor.place === 'person/chest')?.id ||
        '',
      /*
        Always show the 'start time' as 'specific time' on the edit modal.
        Keep this format even if the user selected 'now' on the creation of the patient
        (except when the value is 'Not Defined Yet').
       */
      startTimeType: ['not-defined-yet'].includes(patient.meta?.startTimeType)
        ? patient.meta?.startTimeType
        : 'specific-time',
      startTimeValue: patient.startTime,
      /*
        Always show the 'end time' as 'specific time' on the edit modal.
        Except 
          - when the value is 'Not Defined Yet', and
          - when the value is 'Specify Duration' the field still be the same as the current.
       */
      endTimeType: ['not-defined-yet', 'duration'].includes(
        patient.meta?.endTimeType
      )
        ? patient.meta?.endTimeType
        : 'specific-time',
      endTimeDuration: patient.meta?.endTimeDuration || '',
      endTimeValue: patient.endTime ? patient.endTime : ''
    };
  }

  // For `Create` patient
  return {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    timezone: 'Europe/Copenhagen',
    measurementMethod: algorithms[0]?.name || '',
    sensorThigh: '',
    sensorChest: '',
    startTimeType: 'now',
    startTimeValue: '',
    endTimeType: 'not-defined-yet',
    endTimeDuration: '',
    endTimeValue: ''
  };
};

export const getSensorPlaces = (form, algorithms) => {
  if (!form.measurementMethod) return [];
  const algorithm = algorithms.find(
    item => item.name === form.measurementMethod
  );
  return algorithm?.places || [];
};

export const validateForm = (t, algorithms) => ({
  firstName: form => {
    if (!form.firstName) {
      return t('error:please-enter', { field: t('form.patient-first-name') });
    }
    return '';
  },
  timezone: form => {
    if (!form.timezone) {
      return t('error:please-select', {
        field: t('form.timezone')
      });
    }
    return '';
  },
  measurementMethod: form => {
    if (!form.measurementMethod) {
      return t('error:please-select', { field: t('form.measurement-method') });
    }
    return '';
  },
  sensorThigh: form => {
    const sensorPlaces = getSensorPlaces(form, algorithms);
    if (sensorPlaces.includes('person/thigh') && !form.sensorThigh) {
      return t('error:please-select', { field: t('form.sensor-thigh') });
    }
    return '';
  },
  sensorChest: form => {
    const sensorPlaces = getSensorPlaces(form, algorithms);
    if (sensorPlaces.includes('person/chest') && !form.sensorChest) {
      return t('error:please-select', { field: t('form.sensor-chest') });
    }
    return '';
  },
  startTimeType: form => {
    if (!form.startTimeType) {
      return t('error:please-select', { field: t('form.start-time') });
    }
    return '';
  },
  startTimeValue: form => {
    if (form.startTimeType === 'specific-time' && !form.startTimeValue) {
      return t('error:please-select', {
        field: t('form.time-select/specific-time')
      });
    }
    return '';
  },
  endTimeType: form => {
    if (!form.endTimeType) {
      return t('error:please-select', { field: t('form.end-time') });
    }
    return '';
  },
  endTimeDuration: form => {
    if (form.endTimeType === 'duration' && !form.endTimeDuration) {
      return t('error:please-enter', {
        field: t('form.time-select/duration')
      });
    }
    return '';
  },
  endTimeValue: form => {
    if (form.endTimeType === 'specific-time' && !form.endTimeValue) {
      return t('error:please-select', {
        field: t('form.time-select/specific-time')
      });
    }
    return '';
  }
});
