import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
// *NOTE*:
// Elements must be used as a normal function instead of render JSX component
// Because VictoryChart inspects the props of its children directly
// If we wrap Victory Elements (VictoryLine, VictoryAxis ...) inside another component
// we must merge other props passed from parent, and the merge will be very complex for some cases
// See: https://github.com/FormidableLabs/victory/issues/938
import { Area, AxisX, AxisY, Chart, Line, ZoomIn } from '../elements';
import utils from '../utils/day';

const AccelerometerDay = ({ date, timezone, streams, onSelectTick }) => {
  const t = useTranslate('plot');

  const domain = {
    x: utils.getXDomain(date, timezone),
    y: [-2, 2]
  };

  const xTicks = utils.getXTicks(domain);
  const yTicks = [-2, -1, 0, 1, 2];

  const datax = streams.ts.map((ts, i) => ({
    x: ts,
    y: streams.x[i]
  }));
  const dataxd = streams.ts.map((ts, i) => ({
    x: ts,
    y: streams.x_min[i],
    y0: streams.x_max[i]
  }));
  const datay = streams.ts.map((ts, i) => ({
    x: ts,
    y: streams.y[i]
  }));
  const datayd = streams.ts.map((ts, i) => ({
    x: ts,
    y: streams.y_min[i],
    y0: streams.y_max[i]
  }));
  const dataz = streams.ts.map((ts, i) => ({
    x: ts,
    y: streams.z[i]
  }));
  const datazd = streams.ts.map((ts, i) => ({
    x: ts,
    y: streams.z_min[i],
    y0: streams.z_min[i]
  }));

  return (
    <Chart domain={domain}>
      {AxisX({
        tickValues: xTicks,
        timezone,
        label: t('time-in-hours')
      })}
      {AxisY({ tickValues: yTicks, label: 'g' })}

      {Line({ data: datax, stroke: '#FF0000' })}
      {Area({ data: dataxd, fill: '#FF000030' })}

      {Line({ data: datay, stroke: '#00FF00' })}
      {Area({ data: datayd, fill: '#00FF0030' })}

      {Line({ data: dataz, stroke: '#0000FF' })}
      {Area({ data: datazd, fill: '#0000FF30' })}

      {ZoomIn({
        range: 24,
        xstep: 60 * 60 * 1000,
        xcount: 24,
        domain,
        timezone,
        onSelectTick
      })}
    </Chart>
  );
};

AccelerometerDay.propTypes = {
  date: PropTypes.string.isRequired,
  timezone: PropTypes.string,
  streams: PropTypes.object.isRequired,
  onSelectTick: PropTypes.func.isRequired
};

export default AccelerometerDay;
