import React from 'react';
import { useFormatDate, useTranslate } from 'intl';
import { Dropdown, Form, Input } from 'semantic-ui-react';
import { getEndTime, getStartTime } from 'api/patients';
import DatePicker from 'components/date-picker';
import Section from './section';

const START_TIMES = ['now', 'specific-time', 'not-defined-yet'];
const END_TIMES = ['now', 'specific-time', 'duration', 'not-defined-yet'];

const MonitorPeriod = ({ form, onChanges, validations }) => {
  const t = useTranslate('patient');
  const formatDate = useFormatDate();

  return (
    <Section title={t('form.monitor-period')}>
      <Form.Group widths={2}>
        <Form.Field
          label={t('form.start-time')}
          control={Dropdown}
          placeholder={t('form.start-time')}
          selection
          options={START_TIMES.map(item => ({
            key: item,
            text: t(`form.time-select/${item}`),
            value: item
          }))}
          value={form.startTimeType}
          onChange={(event, { value }) => onChanges.startTimeType(value)}
          error={
            validations?.startTimeType
              ? { content: validations?.startTimeType }
              : false
          }
        />

        {form.startTimeType === 'specific-time' && (
          <Form.Field
            label={t('form.specific-time')}
            control={DatePicker}
            type="date-time"
            timezone={form.timezone}
            timeIntervals={1}
            isClearable
            value={form.startTimeValue}
            onChange={(event, { value }) => onChanges.startTimeValue(value)}
            error={
              validations?.startTimeValue
                ? { content: validations?.startTimeValue }
                : false
            }
          />
        )}
      </Form.Group>

      <Form.Group widths={2}>
        <Form.Field
          label={t('form.end-time')}
          control={Dropdown}
          placeholder={t('form.end-time')}
          selection
          options={END_TIMES.map(item => ({
            key: item,
            text: t(`form.time-select/${item}`),
            value: item
          }))}
          disabled={form.startTimeType === 'not-defined-yet'}
          value={form.endTimeType}
          onChange={(event, { value }) => onChanges.endTimeType(value)}
          error={
            validations?.endTimeType
              ? { content: validations?.endTimeType }
              : false
          }
        />
        {form.endTimeType === 'specific-time' && (
          <Form.Field
            label={t('form.specific-time')}
            control={DatePicker}
            type="date-time"
            timezone={form.timezone}
            timeIntervals={1}
            isClearable
            minDate={getStartTime(form)}
            value={form.endTimeValue}
            onChange={(event, { value }) => onChanges.endTimeValue(value)}
            error={
              validations?.endTimeValue
                ? { content: validations?.endTimeValue }
                : false
            }
          />
        )}
        {form.endTimeType === 'duration' && (
          <Form.Field
            label={t('form.duration')}
            control={props => (
              <div>
                <Input {...props} />
                {form.endTimeDuration && getStartTime(form) && (
                  <div style={{ marginTop: '0.25rem', color: '#767676' }}>
                    {`${formatDate(
                      getEndTime(form),
                      form.startTimeType === 'specific-time'
                        ? 'dd-mm-yyyy hh:mm'
                        : 'dd-mm-yyyy'
                    )} (${t('form.expected')})`}
                  </div>
                )}
              </div>
            )}
            type="number"
            placeholder={t('form.duration')}
            value={form.endTimeDuration}
            onChange={(event, { value }) => onChanges.endTimeDuration(value)}
            error={
              validations?.endTimeDuration
                ? { content: validations?.endTimeDuration }
                : false
            }
          />
        )}
      </Form.Group>
    </Section>
  );
};

export default MonitorPeriod;
