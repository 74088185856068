import React from 'react';
import { useTranslate } from 'intl';
import { Header, Modal, Button, List } from 'semantic-ui-react';
import {
  exportActivityData,
  exportDiary,
  exportCSV,
  exportCSVDetailed,
  exportCSVSummary
} from 'api/nfa-subjects';
import styles from './styles.module.scss';
import { useBulkExport } from './use-bulk-export';

const Item = ({ label, note, children }) => {
  return (
    <List.Item className={styles.item}>
      <div className={styles['item-inner']}>
        <div className={styles.label}>
          {`${label}: `}
          {note && <p className={styles.note}>{note}</p>}
        </div>
        <div className={styles.cta}>{children}</div>
      </div>
    </List.Item>
  );
};

const NFABulkExportModal = ({ subjects, open, onClose }) => {
  const t = useTranslate(['common', 'nfa-subjects', 'measurement-bulk-export']);

  const { exporting: exportingActivity, onExport: onExportActivity } =
    useBulkExport({
      exportAction: 'modal',
      apiExport: exportActivityData,
      subjects,
      onCompleted: onClose
    });

  const { exporting: exportingDiary, onExport: onExportDiary } = useBulkExport({
    exportAction: 'modal',
    apiExport: exportDiary,
    subjects,
    onCompleted: onClose
  });

  const { exporting: exportingCSV, onExport: onExportCSV } = useBulkExport({
    exportAction: 'modal',
    apiExport: exportCSV,
    subjects,
    onCompleted: onClose
  });

  const { exporting: exportingCSVDetailed, onExport: onExportCSVDetailed } =
    useBulkExport({
      exportAction: 'modal',
      apiExport: exportCSVDetailed,
      subjects,
      onCompleted: onClose
    });

  const { exporting: exportingCSVSummary, onExport: onExportCSVSummary } =
    useBulkExport({
      exportAction: 'modal',
      apiExport: exportCSVSummary,
      subjects,
      onCompleted: onClose
    });

  return (
    <Modal open={open}>
      <div className={styles.header}>
        <Header className={styles.title}>
          {t('measurement-bulk-export:header')}
        </Header>
        <p className={styles.description}>
          {t('nfa-subjects:bulk-export.num-of-subjects', {
            count: subjects.length
          })}
        </p>
      </div>
      <Modal.Content className={styles.content}>
        <div className={styles.body}>
          <div className={styles.left}>
            <List relaxed className={styles.list}>
              <Item label={t('nfa-subjects:export-activity-data')}>
                <Button
                  primary
                  size="huge"
                  icon="download"
                  disabled={subjects.length === 0}
                  loading={exportingActivity}
                  onClick={onExportActivity}
                />
              </Item>

              <Item label={t('nfa-subjects:export-diary')}>
                <Button
                  primary
                  size="huge"
                  icon="calendar alternate outline"
                  disabled={subjects.length === 0}
                  loading={exportingDiary}
                  onClick={onExportDiary}
                />
              </Item>

              <Item label={t('nfa-subjects:export-csv')}>
                <Button
                  primary
                  size="huge"
                  icon="file outline"
                  disabled={subjects.length === 0}
                  loading={exportingCSV}
                  onClick={onExportCSV}
                />
              </Item>
            </List>
          </div>
          <div className={styles.right}>
            <List relaxed className={styles.list}>
              <Item label={t('nfa-subjects:export-csv-detailed')}>
                <Button
                  primary
                  size="huge"
                  icon="download"
                  disabled={subjects.length === 0}
                  loading={exportingCSVDetailed}
                  onClick={onExportCSVDetailed}
                />
              </Item>

              <Item label={t('nfa-subjects:export-csv-summary')}>
                <Button
                  primary
                  size="huge"
                  icon="calendar alternate outline"
                  disabled={subjects.length === 0}
                  loading={exportingCSVSummary}
                  onClick={onExportCSVSummary}
                />
              </Item>
            </List>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={onClose}>
          {t('common:close')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default NFABulkExportModal;
