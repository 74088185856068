import { axiosClient } from 'api/axios-client';
import type {
  ExportQueueEntry,
  ExportQueueEntryCommand,
  ExportQueueStatus
} from './types';

export * from './types';

export const fetchExportQueueStatus = async (): Promise<ExportQueueEntry[]> => {
  const response: ExportQueueStatus = await axiosClient
    .get('/api/1.0/export/queue_status')
    .then(response => response.data.value);
  return response.queue_entries;
};

export const commandExportQueue = async (payload: ExportQueueEntryCommand) => {
  return axiosClient.post('/api/1.0/export/queue_command', payload);
};
