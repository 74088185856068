import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
// *NOTE*:
// Elements must be used as a normal function instead of render JSX component
// Because VictoryChart inspects the props of its children directly
// If we wrap Victory Elements (VictoryLine, VictoryAxis ...) inside another component
// we must merge other props passed from parent, and the merge will be very complex for some cases
// See: https://github.com/FormidableLabs/victory/issues/938
import { AxisX, AxisY, Chart, Legend, Stack, ZoomIn } from '../elements';
import utils1 from '../utils/day';
import { colors, fallbackColor } from './configs';
import utils2 from './utils';

const utils = { ...utils1, ...utils2 };

const DerivedDay = ({
  date,
  timezone,
  streams,
  categories: rawCategories,
  onSelectTick
}) => {
  const t = useTranslate('plot');

  const domain = {
    x: utils.getXDomain(date, timezone),
    y: [0, 15]
  };

  const xTicks = utils.getXTicks(domain);
  const yTicks = utils.getYTicks(domain, 5);

  const categories = rawCategories.filter(
    item => item !== 'activity/dummy/time'
  ); // Hard code to remove this as request in issue #282

  const stacks = utils.getStacks(categories).map(category => ({
    color: colors[category] || fallbackColor,
    data: streams.map(item => ({
      x: item.ts,
      y: item.summary[category]
    }))
  }));

  const legends = utils.getLegends(categories).map(category => ({
    name: t(category),
    symbol: {
      fill: colors[category] || fallbackColor,
      type: 'square'
    }
  }));

  return (
    <div>
      <Legend data={legends} />

      <Chart domain={domain}>
        {AxisX({
          tickValues: xTicks,
          timezone,
          label: t('time-in-hours')
        })}
        {AxisY({
          tickValues: yTicks,
          label: t('minutes'),
          verticalLabel: true
        })}

        {Stack({ stacks })}

        {ZoomIn({
          range: 24,
          xstep: 60 * 60 * 1000,
          xcount: 24,
          domain,
          timezone,
          onSelectTick
        })}
      </Chart>
    </div>
  );
};

DerivedDay.propTypes = {
  date: PropTypes.string.isRequired,
  timezone: PropTypes.string,
  streams: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  onSelectTick: PropTypes.func.isRequired
};

export default DerivedDay;
