import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'intl';
// *NOTE*:
// Elements must be used as a normal function instead of render JSX component
// Because VictoryChart inspects the props of its children directly
// If we wrap Victory Elements (VictoryLine, VictoryAxis ...) inside another component
// we must merge other props passed from parent, and the merge will be very complex for some cases
// See: https://github.com/FormidableLabs/victory/issues/938
import { AxisX, AxisY, Chart, Line, ZoomIn } from '../elements';
import utils1 from '../utils/day';
import utils2 from './utils';

const utils = { ...utils1, ...utils2 };

const CapacitanceDay = ({ date, timezone, streams, onSelectTick }) => {
  const t = useTranslate('plot');

  const data = streams.v.map((e, i) => ({ x: streams.ts[i], y: e }));

  const domain = {
    x: utils.getXDomain(date, timezone),
    y: utils.getYDomain(data)
  };

  const xTicks = utils.getXTicks(domain);

  const yStep = utils.getYStep();
  const yTicks = utils.getYTicks(domain, yStep);

  return (
    <Chart domain={domain}>
      {AxisX({
        tickValues: xTicks,
        timezone,
        label: t('time-in-hours')
      })}
      {AxisY({ tickValues: yTicks, label: 'pF' })}

      {Line({ data, stroke: '#FF0000' })}

      {ZoomIn({
        range: 24,
        xstep: 60 * 60 * 1000,
        xcount: 24,
        domain,
        timezone,
        onSelectTick
      })}
    </Chart>
  );
};

CapacitanceDay.propTypes = {
  date: PropTypes.string.isRequired,
  timezone: PropTypes.string,
  streams: PropTypes.object.isRequired,
  onSelectTick: PropTypes.func.isRequired
};

export default CapacitanceDay;
